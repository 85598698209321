import React, { useState } from 'react';
import { IoArrowForward } from 'react-icons/io5';
import theolaptop from "../../Assests/Images/theolaptop.png";
import contactrequest from '../../Services/contact';

const GoogleForm = () => {
    // const [toggleform, setToggleForm] = useState(true)

    const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  const handlecontact =(e)=>{
    e.preventDefault()

    if(name && email && message){
        contactrequest(name.trim(), email, message.trim())
        setName("")
        setEmail("")
        setMessage("")
    }else{
        console.log("invalid")
    }

  }



    return (
        <div id="contact" className="bg-[#160f25] w-full pb-6 pt-24 md:pt-20 ">
            <div>
            <h1 className="  lg:text-4xl font-bold dmd:text-3xl  sm:text-2xl  xs:text-lg   text-[#D94496]  xsm:text-lg    w-4/5  md:w-3/5   xs:w-[90%] sm:w-4/5  sm:p-6  m-auto text-center font-poppins xs:mb-[-15%]  sm:mb-[-15%] lg:mb-[-5%] "> Start Your Journey  </h1>
            </div>
            <div className="flex flex-col md:flex-row xs:flex-col items-center  justify-center md:gap-10 w-[90%] mx-auto">
                {/* Image Section */}
                <div className=" sm:w-3/5 md:w-1/2  md:mb-4  xs:mb-[-20%]   sm:mb-[-10%]   lg:mb-[-5%]  lg:w-2/5  xsm:w-3/5 flex justify-end w-full  relative bottom-2 ">
                    <img
                        // src={"https://trifitindia.com/wp-content/uploads/2024/04/scrolling.gif"}
                        src={theolaptop}
                        alt="Scrolling GIF"
                        className="lg:w-[85%]   rounded-full "
                    />
                </div>

                {/* Form Section */}
                <div className="md:w-1/2 w-full  ">
                    <form onSubmit={handlecontact} className="max-w-md mx-auto">
                        {/* Username Field */}
                        <div className="relative z-0 w-full mb-5 group">
                            <input
                                onChange={(e)=> setName(e.target.value)}
                                type="text"
                                value={name}
                                name="floating_username"
                                id="floating_username"
                                className="block py-2.5 px-0 w-full xs:text-sm  md:text-sm   font-poppins bg-transparent border-0 border-b-2 border-gray-300 appearance-none text-[#F5F5F5] dark:border-gray-600 focus:outline-none focus:ring-0 peer focus:border-[#D94496]  "
                                placeholder=" "
                                required
                            />
                            <label
                                htmlFor="floating_username"
                                className="peer-focus::font-poppins absolute  xs:text-sm  md:text-lg text-lg text-gray-500 dark:text-[#F5F5F5] font-poppins duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#D94496]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Name
                            </label>
                        </div>

                        {/* Email Field */}
                        <div className="relative z-0 w-full mb-5 group">
                            <input
                                type="email"
                                onChange={(e)=> setEmail(e.target.value)}
                                name="floating_email"
                                value={email}
                                id="floating_email"
                                className="block py-2.5 px-0 w-full  xs:text-sm  md:text-sm  bg-transparent border-0 border-b-2 font-poppins  border-gray-300 appearance-none  text-[#F5F5F5] dark:border-gray-600 focus:outline-none focus:ring-0 peer focus:border-[#D94496]   "
                                placeholder=" "
                                required
                            />
                            <label
                                htmlFor="floating_email"
                                className="peer-focus::font-poppins absolute xs:text-sm  md:text-lg  text-lg text-gray-500 font-poppins dark:text-[#F5F5F5] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#D94496]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Email address
                            </label>
                        </div>

                        {/* Message Field */}
                        <div className="relative z-0 w-full mb-5 group">
                            <input
                             type='text'
                                name="floating_message"
                                id="floating_message"
                                onChange={(e)=> setMessage(e.target.value)}
                                value={message}
                                className="block py-2.5 px-0 w-full  xs:text-sm  md:text-sm  bg-transparent font-poppins border-0 border-b-2 border-gray-300 appearance-none text-[#F5F5F5] dark:border-gray-600 focus:outline-none focus:ring-0 peer focus:border-[#D94496]   "
                                placeholder=" "
                                required
                            />
                            <label
                                htmlFor="floating_message"
                                className="peer-focus::font-poppins absolute xs:text-sm  md:text-lg  text-lg text-gray-500 font-poppins dark:text-[#F5F5F5] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#D94496]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Message
                            </label>
                        </div>

                        {/* Submit Button */}
                        <div className=' mt-4  flex relative justify-center '>
                            <button  type='submit' className='text-[#F5F5F5] justify-center items-center  hover:translate-y-2  cursor-pointer font-poppins z-20  lg:text-xl md:text-xl sm:text-lg  px-4 py-2 rounded-2xl    bg-[rgba(217,68,150,1)] '>
                               Submit
                                <IoArrowForward className='inline lg:text-2xl relative font-poppins   ml-2  md:text-2xl sm:text-xl xs:text-lg ' />
                            </button>

                            <button  disabled={true} className='text-[#f5f5f5] justify-center items-center   absolute top-4 border-b-4    font-poppins z-0  lg:text-xl md:text-xl sm:text-lg  px-4   rounded-2xl    bg-white '>
                            Submit
                                <IoArrowForward className='inline lg:text-2xl relative font-poppins   ml-2  md:text-2xl sm:text-xl xs:text-lg ' />
                            </button>

                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default GoogleForm