import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import amazon from "../../Assests/Images/amazon.svg";
import arrow from "../../Assests/Images/arrow.svg";
import flipkart from "../../Assests/Images/flipkart.svg";
import scan from "../../Assests/Images/scan.svg";
import waterdrop from "../../Assests/Images/waterdrop.svg";
import Bottlecard from './Bottlecard';

const Bottleslider = () => {

const handleFlipkartBuy=()=>{
    window.location.href = "https://www.flipkart.com/trifit-trisip-smart-qr-code-750-ml-bottle/p/itm5e0145fd37ce9?pid=BOTH4WFCBHQW2GGA&lid=LSTBOTH4WFCBHQW2GGAQ4RSL0&marketplace=FLIPKART&q=trifit+bottle&store=upp&srno=s_1_3&otracker=search&otracker1=search&fm=Search&iid=ffdb1758-6e4f-4e4e-bc84-7bfdcdb3ac5f.BOTH4WFCBHQW2GGA.SEARCH&ppt=sp&ppn=sp&ssid=kco294j11c0000001728376473376&qH=c3f0cca60bb496d0"
}


const handleAmazonBuy =()=>{
    window.location.href = "https://www.amazon.in/Stainless-Hydration-Tracking-Leak-Proof-Reminder/dp/B0DH8HK1PK/ref=sr_1_5?crid=17CW6GQOA9G75&dib=eyJ2IjoiMSJ9.zWDJ0egQi1HBdRZNciWwd0GQy6s3duxwnPTWVMQ-6BulpHNKoTG6u8oTeieCPYFzgJ9R0U43kzLLXT7b1soGYtowdV7z_uR2gNR1X-CPQVYis-5Z6HN4CMKJBfa3MpE8wdSPI6doJO1RRRY5TsYog9TiBoJ3nvgvUmqJGyZPOGYtkCaL6IdqQ89odhvoe6Cdavsv-jsmwBEQvvTTgWAipQXcXBPXxZsZocq78B209uIQUm9hYZZ3aer4NudZI2VIYk47UCsz_9Dv2YpVDZbYO36nXhmp3hEXmzq6gol6b-k.9bRFhTPCfyFxlrTR-2Yu2qydvHxQKPoS0X6e0kSnxiQ&dib_tag=se&keywords=trifit+bottle&nsdOptOutParam=true&qid=1728376356&sprefix=trifit+bottl%2Caps%2C251&sr=8-5"
}

    return (
        <div className=' bg-[#160F25] pb-2   md:pb-12'>
            <div className='w-[90%] m-auto py-6'>
                <div className='text-[#F5F5F5] '>
                    <p className='text-center'>TriSip By TriFit</p>
                    <h1 className="lg:text-2xl sm:text-xl  xs:text-lg    xsm:text-lg  tracking-wide  sm:mt-2 m-auto   text-center font-poppins" >
                        Stay Hydrated in Style – <span className='text-[#D94496] '>
                            Get Your TriSip Smart Bottle Now!
                        </span>
                    </h1>
                </div>
            </div>
            <div className='flex justify-center   md:flex-row xs:flex-col '>
                <div className='md:w-2/5 sm:w-full '>
                    <Bottlecard />
                </div>
                <div className='xs:w-[90%] m-auto  md:w-1/2 '>
                    <div className='text-[#F5F5F5]  ml-6 flex justify-center items-center mb-6 '>
                        <div className=' bg-[#D94496] xs:p-2 xsm:p-4 rounded-full '>
                            <img className='xs:w-[70px]  xsm:w-[40px]'  src={scan} alt="" />
                        </div>

                        <div>
                            <h2 className=' xs:w-4/5  md:w-4/5 m-auto  mb-2 md:text-lg  xs:text-start md:text-start  xs:text-base font-poppins'>Seamless, Intuitive Hydration Tracking</h2>
                            <p className='xs:w-4/5  md:w-4/5 m-auto xs:text-start md:text-start  xs:text-sm font-poppins'  >  Open the TriFit app and scan the QR code on the bottle to seamlessly sync
                                your water intake with the app, making tracking effortless.</p>
                        </div>

                    </div>
                    <div className='text-[#F5F5F5]  ml-6 flex justify-center items-center mb-6 '>
                        <div className=' bg-[#D94496]  xs:p-2 xsm:p-4  rounded-full '>
                            <img className='xs:w-[70px]  xsm:w-[40px]' src={arrow} alt="" />
                        </div>

                        <div>
                            <h2 className=' xs:w-4/5 mb-2  md:w-4/5 m-auto  md:text-lg   xs:text-start  md:text-start  xs:text-base font-poppins'>Set Daily Goals </h2>
                            <p className='xs:w-4/5  md:w-4/5 m-auto  xs:text-start md:text-start  xs:text-sm font-poppins'  >  Use the TriFit app to customize your daily water intake goals and receive 
                            helpful reminders to ensure you stay on track throughout the day. </p>
                        </div>
                    </div>
                    <div className='text-[#f5f5f5] ml-6 flex justify-center items-center mb-6 '>
                        <div className=' bg-[#D94496]  xs:p-2 xsm:p-4   rounded-full '>
                            <img className='xs:w-[70px]  xsm:w-[40px]' src={waterdrop} alt="" />
                        </div>


                        <div>
                            <h2 className=' xs:w-4/5  mb-2 md:w-4/5 m-auto  md:text-lg   xs:text-start  md:text-start  xs:text-base font-poppins'>Track Your Intake  </h2>
                            <p className='xs:w-4/5  md:w-4/5 m-auto  xs:text-start  md:text-start  xs:text-sm font-poppins'  >  Each time you refill or drink, scan the QR code on the bottle to log your water 
                            consumption instantly in the app, allowing you to monitor your progress.</p>
                        </div>
                    </div>

                    <div className=' flex  xs:flex-col xsm:flex-row gap-8 m-auto w-fit pb-4'>
                        <div className=' mt-2 flex  relative justify-center  '>
                            <button onClick={handleFlipkartBuy} className='text-[#F5F5F5]  justify-center items-center hover:translate-y-2  cursor-pointer font-poppins z-20  lg:text-xl md:text-sm sm:text-sm  px-4 py-2 rounded-2xl  flex gap-4  bg-[rgba(0,193,129,1)] '>
                                <img src={flipkart} alt="" />
                                Buy on Flipkart

                            </button>

                            <button disabled={true} className='text-[#F5F5F5]  justify-center items-center   absolute top-4 border-b-4    font-poppins z-0  lg:text-xl md:text-sm sm:text-sm  px-4   rounded-2xl flex gap-4  bg-[rgba(0,193,129,1)] '>
                            <img src={flipkart} alt="" />
                                Buy on Flipkart

                            </button>
                            
                        </div>
                        <div className=' mt-2 flex relative justify-center '>
                        <button  onClick={handleAmazonBuy} className='text-[#F5F5F5]  justify-center items-center hover:translate-y-2  cursor-pointer font-poppins z-20  lg:text-xl md:text-sm sm:text-sm  px-4 py-2 rounded-2xl flex gap-4  bg-[rgba(0,193,129,1)]'>
                        <img src={amazon} alt="" />
                                Buy on Amazon
                            </button>

                            <button disabled={true} className='text-[#F5F5F5]  justify-center items-center   absolute top-4 border-b-4    font-poppins z-0  lg:text-xl md:text-sm sm:text-sm  px-4   rounded-2xl flex gap-4   bg-[rgba(0,193,129,1)] '>
                            <img src={amazon} alt="" />
                                Buy on Amazon
                            </button>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Bottleslider

