import axios from "axios";
import toast from "react-hot-toast";


const notifysuccess = () => {
    toast.success('You have Subscribed to Newsletter!', {
        style: {
            border: "2px solid  #120426 ",
            fontWeight: "700",
            color: "#120426 "

        }

    })
}


const notifyalready = () => {
    toast.error('You have already Subscribed!!', {
        style: {
            border: "2px solid  #120426 ",
            fontWeight: "700",
            color: "#120426 "

        }

    })
}


const tryagain = () => {
    toast.error('Please try again!!', {
        style: {
            border: "2px solid  #120426 ",
            fontWeight: "700",
            color: "#120426 "

        }

    })
}



const subscribeNewsletter = async (email) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_SERVER_URL}/newsletter/subscription`, {
            email: email
        })
        // console.log(response.data, "hello skjdklsldll")
        notifysuccess()
        
    } catch (error) {
        // console.log(error.response)
        if(error.response.data.message == "User has already subscribed to the news letter"){
            notifyalready()
        }else{
            tryagain() 
        }
    }
   
}

export default subscribeNewsletter;
