import axios from "axios"
import toast from "react-hot-toast"


const messageasuccess = () => {
    toast.success('Message sent Successfully', {
        style: {
            border: "2px solid  #120426 ",
            fontWeight: "700",
            color: "#120426 "

        }

    })
}

const tryagain = () => {
    toast.error('Please try again!', {
        style: {
            border: "2px solid  #120426 ",
            fontWeight: "700",
            color: "#120426 "

        }

    })
}



const contactrequest = async (name, email, message) => {
    let data = {
        name, email, message
    }

    // console.log(name, email, message)
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_SERVER_URL}/qna/query`,data)
        console.log(response.data,"data here")
        messageasuccess()
        
    } catch (error) {
        
        tryagain()
    }


}

export default contactrequest