import React from 'react'
import { IoArrowForward } from 'react-icons/io5'
import mobile5 from "../../Assests/Images/mobile5.svg"

const HeroSection = () => {
    return (
        <div id="home" className=' bg-[#160F25] mt-1 pt-4  xs:pb-8 md:pb-20'>
            <div className='w-[95%] m-auto   flex md:flex-row xs:flex-col-reverse  items-center '>
                <div className='w-full'>
                    <div className='text-white'>
                        <h1 className="lg:text-4xl font-bold dmd:text-3xl  sm:text-2xl  xs:text-lg   text-[#D94496] md:mb-0 xs:mb-4  xsm:text-lg tracking-wide  w-full lg:w-[80%] sm:p-6  m-auto sm:mt-4  text-center font-poppins  "> Embrace Holistic Wellness and Achieve True Balance </h1>
                        <p className="lg:text-lg sm:text-lg  xs:text-xs   text-[#F5F5F5] xsm:text-base  text-center tracking-wide   w-4/5     m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins  "> Engage your body, calm your mind, and nurture your spirit with workout challenges, mental health tools, and spiritual guidance — all available in a single, easy-to-use platform that brings holistic well-being within reach. </p>
                    </div>
                    <div className=' mt-4  flex relative justify-center '>
                        <button className=' text-[#F5F5F5]  justify-center items-center hover:translate-y-2  cursor-pointer font-poppins z-20  lg:text-2xl md:text-xl sm:text-lg  px-4 py-2 rounded-2xl    bg-[rgba(217,68,150,1)] '>
                            Let's Go
                            <IoArrowForward className='inline lg:text-4xl relative font-poppins   ml-2  md:text-2xl sm:text-xl xs:text-lg ' />
                        </button>

                        <button disabled={true} className='text-[#F5F5F5] justify-center items-center   absolute top-4 border-b-4    font-poppins z-0  lg:text-2xl md:text-xl sm:text-lg  px-4   rounded-2xl    bg-[#f5f5f5] '>
                            Let's Go
                            <IoArrowForward className='inline lg:text-4xl relative font-poppins   ml-2  md:text-2xl sm:text-xl xs:text-lg ' />
                        </button>

                    </div>
                </div>
                <div className='    md:w-1/2  lg:mt-10  sm:w-1/3 md:pb-0 xs:pb-6   min-h-[350px]    xsm:w-2/5  xs:w-1/2  mx-auto'>
                    <div>
                        {/* <img src={stage}    alt="Stage" className="w-full  rounded-2xl  " /> */}
                        <img 
                            src={mobile5}
                            alt="Mobile"
                            className='  md:m-0 xs:m-auto w-full   md:w-3/5  ' 
                        />
                    </div>
                    {/* <div>
                        <img src={mobile10} alt="" />
                    </div> */}
                </div>


                {/* <div className=' xs:w-full   xsm:w-[80%] border-2 border-red-800 lg:w-[120%]  py-4 xs:  md:top-16 lg:top-0  mx-auto'>
                    <div className='relative  '>
                        {/* <img src={stage}    alt="Stage" className="w-full  rounded-2xl  " /> */}
                        {/* <img 
                            src={mobile5}
                            alt="Mobile"
                            className=' animate__animated animate__fadeIn   absolute xs:top-[15%] xsm:top-[30%] left-1/2 transform -translate-x-1/2 -translate-y-1/2  xs:w-2/5  xsm:w-1/4 ' 
                        />
                    </div>
                </div> */} 


                {/* <div className='relative w-4/5     mx-auto'>
                    <div className='relative '>
                        <img src={stage}    alt="Stage" className="w-full rounded-lg  " />
                        <img 
                            src={mobile}
                            alt="Mobile"
                            className='absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/4  ' 
                        />
                    </div>
                </div> */}

            

            </div>
        </div>
    )
}

export default HeroSection