import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { IoArrowForward } from 'react-icons/io5'
import message from "../../Assests/Images/message.svg"
import newletter from "../../Assests/Images/newsletter.svg"
import subscribeNewsletter from '../../Services/newletter'

const notify4 = () => {
    toast.error('Please enter valid email!', {
        style: {
            border: "2px solid  #120426 ",
            fontWeight: "700",
            color: "#120426 "

        }

    })
}

const Newsletter = () => {
    const [newletteremail, setnewletteremail] = useState("")

    const handlesubscribe = () => {

        if (/\S+@\S+\.\S+/.test(newletteremail)) {

            subscribeNewsletter(newletteremail.trim())
            setnewletteremail("")
        } else {
            notify4()
        }
    }


    const handlechangenewletter = (e) => {

        setnewletteremail(e.target.value)
        // console.log(e.target.value, "hberfebrerern")
    }

    return (
        <div className='w-full md:py-24  md:pb-10  xs:py-8    bg-[#160F25]'>
            <div className=' w-[90%] m-auto flex  md:flex-row xs:flex-col  justify-center '>
                <div className=' xs:m-auto  md:m-0 xs:mb-6          min-h-[245px] '>
                    <img src={newletter} alt="" />

                </div>
                <div className=' md:w-1/2 relative bottom-6  xs:w-full md:my-0 xs:my-4  '>
                    <div>
                        <h1 className="lg:text-[32px] font-bold dmd:text-3xl  sm:text-2xl  xs:text-lg   text-[#D94496] md:mb-0 xs:mb-4  xsm:text-lg tracking-wide   w-4/5  xs:w-[100%] sm:w-4/5  sm:p-6  m-auto sm:mt-4  text-center font-poppins  ">Subscribe to our  Newsletter!</h1>
                        <p className=' w-4/5 m-auto text-center text-white  xs:text-sm  font-poppins'> Get the most popular and trending blogs delivered directly to your inbox. Stay informed with curated tips, insights, and expert content in your field. </p>
                    </div>
                    <div className=' text-white  lg:ml-24   md:my-4   xs:my-4 relative  xs:w-[95%]   md w-4/5   m-auto'>
                        <img className=' w-8 absolute top-4 left-2 z-20  bg-transparent ' src={message} alt="" />
                        <input onChange={handlechangenewletter} value={newletteremail} className=' xs:w-full  outline-none  lg:w-4/5 shadow-lg text-start xs:pl-16  lg:pl-16   py-4 bg-[#413651]  rounded-lg absolute ' type="email" placeholder='Enter your email here' />

                    </div>
                    <div className=' xs:mt-24  md:mt-20 relative  w-4/5  lg:ml-0  sm:w-[85%] flex md:float-end  xs:justify-center  xs:ml-10 lg:justify-start   '>
                        {/* <button className='text-white justify-center  relative   xs:left-10 items-center  cursor-pointer font-poppins   lg:text-2xl md:text-xl sm:text-lg  px-4 py-2 rounded-2xl  border-b-2  bg-[rgba(217,68,150,1)] '>
                          Subscribe
                            <IoArrowForward className='inline lg:text-4xl relative font-poppins   ml-2  md:text-2xl sm:text-xl xs:text-lg ' />
                        </button> */}

                        <button onClick={handlesubscribe} id="blogs" className='text-white justify-center items-center hover:translate-y-2  absolute     cursor-pointer  font-poppins z-20  lg:text-2xl md:text-xl sm:text-lg  px-4 py-2 rounded-2xl    bg-[rgba(217,68,150,1)] '>
                            Subscribe
                            <IoArrowForward className='inline lg:text-4xl relative font-poppins   ml-2  md:text-2xl sm:text-xl xs:text-lg ' />
                        </button>

                        <button disabled={true} className='text-white justify-center items-center  absolute top-4  border-b-4    font-poppins z-0  lg:text-2xl md:text-xl sm:text-lg  px-4   rounded-2xl    bg-white '>
                            Subscribe
                            <IoArrowForward className='inline lg:text-4xl relative font-poppins   ml-2  md:text-2xl sm:text-xl xs:text-lg ' />
                        </button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Newsletter

