import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import blogcard1 from "../../Assests/Images/blogcard1.jpg";
import blogcard2 from "../../Assests/Images/blogcard2.jpg";
import blogcard3 from "../../Assests/Images/blogcard3.jpg";
import blogcard4 from "../../Assests/Images/blogcard4.jpg";
import blogcard5 from "../../Assests/Images/blogcard5.jpg";

const Blogscard = () => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const handleopen = (url) => {
        window.open(url, '_blank');

    }

    return (
        <div className='text-white  rounded-2xl py-6  bg-[#160F25]  flex  md:justify-end mx-4  sm:justify-center    '>
            <Carousel className='  xs:w-[85%] xsm:w-[75%] sm:w-[55%] rounded-2xl  md:w-[85%] lg:w-[75%]  m-auto   ' showIndicators={false} dynamicHeight={false} swipeable={false} emulateTouch={false} verticalSwipe={"natural"} showArrows={false} centerMode={true} centerSlidePercentage={isSmallScreen ? 100 : 32} showStatus={false} showThumbs={false} autoPlay={true} interval={1500} infiniteLoop={true}   >
                <div onClick={() => handleopen("https://plunge.com/blogs/blog/health-wellness-trends")} className=' mb-2 ml-2 cursor-pointer                                     min-h-[400px]   '>
                    <h1 className="  font-bold  xs:text-base lg:text-lg mb-2  xsm:text-sm   text-[#F5F5F5] sm:p-2  m-auto sm:mt-4  text-center font-poppins "  > The Benefits of Cold Plunging for Mind and Body </h1>
                    <img className='  rounded-2xl  ' src={blogcard1} alt="" />
                    <p className="lg:text-sm sm:text-sm   xs:text-xs  mt-2 md:mt-4   xsm:text-xs  text-center tracking-wide      m-auto  font-poppins   text-[#F5F5F5]    "  > Cold plunging has become a popular wellness trend, known for reducing anxiety and improving muscle recovery. Learn how this simple practice can enhance both mental clarity and physical health.</p>
                    {/* <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={blogcard1} alt="" /> */}
                </div>

                <div onClick={() => handleopen("https://danuwellness.com/2024/01/05/top-holistic-wellness-trends-to-elevate-your-health-in-2024/")} className=' mb-2 mx-2 cursor-pointer           min-h-[400px]  '>
                    <h1 className=" font-bold   xs:text-base mb-2  lg:text-lg   xsm:text-sm  text-[#F5F5F5]   sm:p-2  m-auto sm:mt-4  text-center font-poppins "  > Holistic Health: Balancing Mind, Body, and Spirit </h1>
                    {/* <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={blogcard1} alt="" /> */}
                    <img className='rounded-2xl' src={blogcard2} alt="" />
                    <p className="lg:text-sm sm:text-sm   xs:text-xs mt-2  md:mt-4  xsm:text-xs  text-center     m-auto  font-poppins   text-[#F5F5F5]   "  > Holistic health practices, such as yoga and mindfulness, are essential for achieving overall wellness. Discover how integrating these practices into your routine can improve physical and emotional well-being.</p>
                </div>

                <div onClick={() => handleopen("https://www.healingholidays.com/blog/our-top-wellness-trends-for-2024")} className=' mb-2  mr-2 cursor-pointer                                   min-h-[400px]  '>
                    <h1 className=" font-bold   xs:text-base mb-2  lg:text-lg    xsm:text-sm  text-[#F5F5F5]  sm:p-2  m-auto sm:mt-4  text-center font-poppins "  > The Role of Smart Sleep Technology in 2024 </h1>
                    {/* <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={blogcard1} alt="" /> */}
                    <img className='rounded-2xl' src={blogcard3} alt="" />
                    <p className="lg:text-sm sm:text-sm   xs:text-xs  mt-2  md:mt-4 xsm:text-xs  text-center      m-auto font-poppins   text-[#F5F5F5]    "  > Smart sleep technology is transforming the way we approach rest, helping individuals optimize their sleep patterns and improve their overall health.</p>
                </div>

                <div onClick={() => handleopen("https://discoverhealing.com/holistic-health-articles/holistic-health-and-hydration/")} className=' mb-2  mr-2 cursor-pointer               min-h-[400px]  '>
                    {/* <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={blogcard1} alt="" /> */}
                    <h1 className=" font-bold  xs:text-base mb-2  lg:text-lg    xsm:text-sm  text-[#F5F5F5]  sm:p-2  m-auto sm:mt-4  text-center font-poppins "  >Hydration: Key to Holistic Wellness  always</h1>
                    <img className='rounded-2xl' src={blogcard4} alt="" />
                    <p className="lg:text-sm sm:text-sm   xs:text-xs  mt-2  md:mt-4  xsm:text-xs  text-center       m-auto  font-poppins text-[#F5F5F5]     "  > Proper hydration supports everything from cellular function to emotional stability. Learn how staying hydrated can boost both mental and physical health.</p>
                </div>

                <div onClick={() => handleopen("https://www.theultimatehuman.com/post/5-biohacking-trends-to-watch-for-in-2024-")} className=' mb-2  mr-2 cursor-pointer                   min-h-[400px]  '>
                    <h1 className="font-bold   xs:text-base mb-2  lg:text-lg    xsm:text-sm   text-[#F5F5F5] sm:p-2  m-auto sm:mt-4  text-center font-poppins "  > Longevity and Wellness: Key Trends for 2024</h1>
                    {/* <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={blogcard1} alt="" /> */}
                    <img className='rounded-2xl' src={blogcard5} alt="" />
                    <p className="lg:text-sm sm:text-sm   xs:text-xs  mt-2 md:mt-4 xsm:text-xs  text-center       m-auto  font-poppins  text-[#F5F5F5]  "  > Explore the latest longevity trends, from NAD supplements to strength training, that can enhance your vitality and improve the quality of life as you age.</p>
                </div>









            </Carousel>
        </div>
    )
}

export default Blogscard

