
import React from 'react';
import footerbg from "../Assests/Images/footerbg.svg";

import { useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import applestore from "../Assests/Images/applestore.svg";
import fb from "../Assests/Images/fb.svg";
import insta from "../Assests/Images/insta.svg";
import linkedin from "../Assests/Images/linkedin.svg";
import playstore from "../Assests/Images/playstore.svg";
import rightarrow from "../Assests/Images/rightarrow.svg";
import theoup from "../Assests/Images/theoup.svg";
import trifitsmalllogo from "../Assests/Images/trifitsmalllogo.svg";
import utube from "../Assests/Images/utube.svg";

const Footer = () => {
    const navigate = useNavigate()

    const handleopensite = (url) => {
        window.open(url, '_blank');

    }
    return (
        <footer className="bg-[#160f25] text-white pt-16 pb-4 font-poppins    p-8  ">
            {/* Wrapper with background color and image */}
            <div
                className={`  xs:w-[100%] xsm:w-[90%]    m-auto bg-cover bg-center py-6 lg:pt-40 overflow-hidden md:px-0 xs:px-6  rounded-2xl  relative flex lg:flex-row xs:flex-col   `}
                style={{
                    backgroundImage: `url(${footerbg})`,
                    backgroundColor: '#413651 ',
                    backgroundBlendMode: 'overlay'  // To blend color and image
                }}
            >

                <div className='flex flex-col lg:relative lg:top-[-70px]   '>
                    {/* <div className='xs:ml-0  md:ml-20'>
                        <h1 className="lg:text-4xl font-bold dmd:text-3xl  sm:text-2xl  xs:text-lg   text-[#D94496] md:mb-0 xs:mb-4  xsm:text-lg    lg:w-full xs:w-[90%] sm:w-4/5 my-2  m-auto sm:mt-4   font-poppins   "> Download The App Now !  </h1>
                        <p className="lg:text-lg md:text-base  xs:text-xs    md:text-start text-center    xsm:text-base  tracking-wide md:w-4/5    xsm:mt-4 lg:mt-0  font-poppins " > Achieve your hydration goals with the TriFit app. Track your water intake, get reminders,
                            and stay refreshed every day. Download now on the Play Store and App Store! </p>
                    </div> */}

                    <div className='xs:ml-0 lg:ml-20 flex  md:w-full flex-col lg:items-end'>
                        <h1 className="lg:text-4xl font-bold dmd:text-3xl sm:text-2xl xs:text-lg text-[#D94496] md:mb-0 xs:mb-4 xsm:text-lg lg:w-full xs:w-[95%] sm:w-4/5 my-2 m-auto sm:mt-4 font-poppins text-center md:ml-10 lg:ml-0">
                            Download The App Now !
                        </h1>
                        <p className="lg:text-lg md:text-base xs:text-xs  text-center lg:text-center  xsm:text-base  m-auto w-full  md:w-4/5 xsm:mt-4  lg:mt-0 font-poppins">
                            Achieve your hydration goals with the TriFit app. Track your water intake, get reminders, and stay refreshed every day. Download now on the Play Store and App Store!
                        </p>
                    </div>




                    <div className=' flex  xs:w-full md:w-4/5 lg:w-full xs:flex-col md:flex-row  gap-2 md:gap-6 xs:justify-center  xs:items-center  xs:ml-0 mt-6   md:pb-4'>

                        <div>
                            <img className='sm:w-44 xs:w-36 md:ml-20' src={playstore} alt="" />
                        </div>
                        <div>
                            <img className='sm:w-44 xs:w-36' src={applestore} alt="" />
                        </div>

                    </div>
                </div>
                {/* <div className='   relative  border-2 border-red-800  w-full '>
                    <img className=" xs:w-full xsm:w-4/5  sm:w-3/5   m-auto   md:w-[250px]  lg:w-[340px] relative top-16  " src={theoup} alt="" />
                </div> */}
                <div className='    md:relative  w-full '>
                    <img className=" xs:w-full xsm:w-[90%]  sm:w-3/5  lg:absolute  m-auto xs:relative  xs:bottom-[-60px]  md:bottom-[-75px]   lg:bottom-[-21%]  md:w-1/2  lg:w-[100%]  xl:bottom-[-80px]  xl:w-4/5" src={theoup} alt="" />
                </div>


            </div>
            {/* //bottommost foooter */}



            <div className=' flex md:flex-row flex-col-reverse mt-4'>
                <div className='md:w-1/2 md:ml-8 flex md:flex-row flex-col '>
                    <div className='flex md:flex-col flex-col-reverse'>
                        <div className='flex md:flex-col flex-col '>
                            <div className='pt-6 pb-6 flex justify-center items-center'>
                                <img className=' bg-white   w-16  rounded-full' src={trifitsmalllogo} alt="Trifit" />
                                <p className='text-white text-3xl ml-2 font-semibold font-poppins '>Trifit</p>
                            </div>
                            <div>
                                <p className=' w-4/5 m-auto text-center  text-[##F5F5F5]  xs:text-sm font-poppins'>
                                    TriFit is committed to enhancing your wellness journey with simple, effective tools. Our app helps users stay on track with their goals and promotes a healthier lifestyle. Join us in making healthy living easier and more accessible.

                                </p>
                            </div>
                        </div>
                        <div className='flex     justify-center '>
                            <div className='flex flex-row md:justify-start  gap-4 justify-center mt-4 '>


                                <div className=' lg:p-4   xs:p-4 bg-[#413651] w-fit rounded-full '>
                                    <img className='lg:w-8  xs:w-10 xsm:w-6 ' src={fb} alt="" />
                                </div>
                                <div onClick={() => handleopensite("https://www.instagram.com/trifit.in/reels/?hl=en")} className=' lg:p-4  cursor-pointer xs:p-4 bg-[#413651]  w-fit rounded-full '>
                                    <img className='lg:w-8  xs:w-10 xsm:w-6 ' src={insta} alt="" />
                                </div>
                                <div onClick={() => handleopensite("https://www.youtube.com/@Trifit.social")} className=' lg:p-4 cursor-pointer  xs:p-4 bg-[#413651]  w-fit rounded-full '>
                                    <img className='lg:w-8  xs:w-10 xsm:w-6 ' src={utube} alt="" />
                                </div>
                                <div onClick={() => handleopensite("https://www.linkedin.com/in/trifitsocial/")} className=' lg:p-4  cursor-pointer xs:p-4 bg-[#413651]  w-fit rounded-full '>
                                    <img className='lg:w-8  xs:w-10 xsm:w-6 ' src={linkedin} alt="" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='w-full '>
                        <h4 className='font-poppins my-2  lg:text-lg   text-[#F5F5F5] sm:text-lg  mb-2 md:mt-10 xs:text-base text-center'>Pages</h4>

                        <ScrollLink to="home" smooth={true} duration={800}>
                            <p className=' w-full m-auto text-center  text-[#F5F5F5]  cursor-pointer  mb-2  xs:text-sm font-poppins'>
                                Home
                            </p>
                        </ScrollLink>

                        <ScrollLink to="home" smooth={true} duration={800}>
                            <p className=' w-full m-auto text-center  text-[#F5F5F5]  cursor-pointer  mb-2  xs:text-sm font-poppins'>
                                About Us
                            </p>
                        </ScrollLink>

                        <ScrollLink to="contact" smooth={true} duration={800}>
                            <p className=' w-full m-auto text-center  text-[#F5F5F5]  cursor-pointer  mb-2 xs:text-sm font-poppins'>
                                Contact Us
                            </p>
                        </ScrollLink>

                        <ScrollLink to="blogs" smooth={true} duration={800}>
                            <p className=' w-full m-auto text-center text-[#F5F5F5]   cursor-pointer mb-2  xs:text-sm font-poppins'>
                                Blogs
                            </p>
                        </ScrollLink>

                        <p className=' w-full m-auto text-center cursor-pointer text-[#F5F5F5]   mb-2  xs:text-sm font-poppins'>
                            Sign up
                        </p>

                    </div>
                </div>

                <div className='md:w-1/2 w-full'>
                    <h4 className='font-poppins my-2  mb-4 w-full lg:text-2xl md:text-xl text-[#F5F5F5]  sm:text-lg   md:mt-10 xs:text-base text-center'>Get the latest information</h4>
                    <div className='relative flex justify-center '>
                        <input className='bg-[#413651]  outline-none w-full md:w-3/5  py-4 px-4 rounded-lg  font-poppins' type="text" placeholder='Email address' />
                        <img className=' w-12  p-2  absolute top-1 right-2 md:right-[21%] rounded-lg bg-[#D94496]  font-poppins' src={rightarrow} alt="" />
                    </div>
                </div>

            </div>
            <div className='w-[90%] m-auto border-2 border-[rgba(109,109,109,1)]  mt-6 mb-4 '>

            </div>
            <div className='flex md:flex-row flex-col '>
                <p className=' w-full m-auto text-center   text-[#F5F5F5]  xs:text-[10px] xsm:text-base font-poppins'>
                    Copyright © 2024 TriFit India. All Rights Reserved.
                </p>
                <p
                    onClick={() => navigate("/privacy")}
                    className='w-full m-auto text-center cursor-pointer text-[#F5F5F5]  xs:text-[10px] xsm:text-base font-poppins underline'
                >
                    User Terms & Conditions | Privacy Policy
                </p>

            </div>


        </footer>
    );
}

export default Footer;


