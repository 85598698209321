import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import bottleback from "../../Assests/Images/bottleback.jpeg";

const Bottlecard = () => {
    return (
        <div className='text-[#f5f5f5]  py-6   flex  md:justify-end mx-4  sm:justify-center '>
            <Carousel className=' sm:w-3/5   md:w-[95%]  lg:w-[76%]  xs:w-4/5 xs:m-auto lg:m-0 lg:ml-8 '  showIndicators={false}  dynamicHeight={false}   swipeable={false} emulateTouch={false} verticalSwipe={"natural"} showArrows={false}  centerMode={true} centerSlidePercentage={95} showStatus={false} showThumbs={false} autoPlay={true} interval={1500} infiniteLoop={true}   >

                <div className=' mb-2 ml-1     min-h-[340px] '>
                    <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl'  src={"https://m.media-amazon.com/images/I/61oQy10qZ5L._SX679_.jpg"} alt="" />
                </div>

                <div className=' mb-2 mx-1         min-h-[340px]  ' >
                    <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl ' src={"https://m.media-amazon.com/images/I/712JtUygqfL._SX679_.jpg"}  alt="" />
                </div>

                <div className=' mb-2  mr-1     min-h-[340px]     '>
                    <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={bottleback} alt="" />
                </div>

                <div className=' mb-2  mr-1         min-h-[340px]    '>
                    <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={"https://m.media-amazon.com/images/I/516lG2HMCeL._SX679_.jpg"} alt="" />
                </div>
                
                <div className=' mb-2  mr-1             min-h-[340px]    '>
                    <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={"https://m.media-amazon.com/images/I/71uRYU4zGWL._SY879_.jpg"} alt="" />
                </div>

                <div className=' mb-2  mr-1     min-h-[340px]    '>
                    <img className='xsm:w-[300px] xsm:h-[350px]  xs:w-[250px] xs:h-[250px] rounded-2xl' src={"https://m.media-amazon.com/images/I/71FsfEDjVkL._SX679_.jpg"} alt="" />
                </div>

              
              

              
    
            </Carousel>
        </div>
    )
}

export default Bottlecard

