import { SELECT_HOUSE, SELECT_USERNAME } from "./actionTypes";


const initialState = {
 username : "",
 house:null
 
};

export const reducer = (state = initialState,{ type , payload }) => {
    switch (type) {
       case  SELECT_USERNAME :
        return {
            ...state ,
            username : payload
        }
        case  SELECT_HOUSE :
            return {
                ...state ,
                house : payload
            }

        default:
            return state;
    }
};