import React from 'react';
import Footer from '../../Components/Footer';
import Blogs from './Blogs';
import Bottleslider from './Bottleslider';
import GoogleForm from './GoogleForm';
import HeroSection from './HeroSection';
import Newsletter from './Newsletter';
import Slider from './Slider';
import Trisipcard from './Trisipcard';

export const Homepage = () => {
    // const { isSignedIn, user } = useUser();
    // const navigate = useNavigate()


    // const notify3 = (loggedinuser) => {
    //     toast.success(`😊Welcome  ${loggedinuser}`, {
    //         style: {
    //             border: "2px solid  #120426 ",
    //             fontWeight: "700",
    //             color: "#120426 "

    //         }
    //     });

    // }

    // const notify4 = () => {
    //     toast.success('Registration Successfull', {
    //         style: {
    //             border: "2px solid  #120426 ",
    //             fontWeight: "700",
    //             color: "#120426 "

    //         }

    //     })
    // }



    // useEffect(() => {
    //     // localStorage.setItem("isloggedin", false)

    //     // console.log(isSignedIn, "value ofisisinged ininisde useefect")
    //     const isloggedinvalue = localStorage.getItem("isloggedin")
    //     // console.log(  isloggedinvalue,"herer")

    //     if (isSignedIn) {
    //         if (isloggedinvalue === "false") {
    //             notify3(`${user.fullName}`)
    //             localStorage.removeItem("isloggedin")
    //             localStorage.setItem("isloggedout", false)

    //         }

    //     }

    //     if (isSignedIn) {
    //         // notify3()
    //         const data = {
    //             name: user?.fullName,
    //             email: user?.primaryEmailAddress?.emailAddress,
    //             google_id: user?.id,
    //             house_id: localStorage.getItem("house"),
    //             user_name: localStorage.getItem("username")
    //         };

    //         let message = saveDataUser(data)
    //         if (message === "User registration successful") {
    //             notify4()
    //             navigate("/success")
    //         }

    //     }

    // }, [isSignedIn])


    // console.log(localStorage.getItem("house"), "house if")
    // console.log(localStorage.getItem("username", "username"))

    return (
        <div >
         
            <HeroSection />
            <Trisipcard />
            <Newsletter />
            <Blogs />
            <Bottleslider />
            <Slider />
            <GoogleForm />
            <Footer />

        </div>
    )
}





// <div>
// <div className="grid grid-cols-1  sm:flex items-center justify-between max-w-7xl mx-auto p-8 ">
//     {/* Left Side: GIF */}
//     <div className="flex-1 mr-4">
//         <img
//             src="https://trifitindia.com/wp-content/uploads/2024/04/computer-1.gif"
//             alt="Computer Animation"
//             className="sm:w-4/5 lg:w-3/5  xsm:w-full  xs:4/5  h-auto rounded-lg"
//         />
//     </div>

//     {/* Right Side: Form */}
//     <div className="flex-1   max-w-lg">
//         <div className="p-8 bg-white shadow-2xl border-2 border-gray-500  rounded-2xl">
//             <form className="space-y-6">
//                 <div>
//                     <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
//                     <input
//                         type="text"
//                         id="name"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
//                         placeholder="Enter your name"
//                     />
//                 </div>

//                 <div>
//                     <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
//                     <input
//                         type="email"
//                         id="email"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
//                         placeholder="Enter your email"
//                     />
//                 </div>

//                 <div>
//                     <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
//                     <textarea
//                         id="message"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
//                         rows="4"
//                         placeholder="Your message">
//                     </textarea>
//                 </div>

//                 <button
//                     type="submit"
//                     className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600">
//                     Submit
//                 </button>
//             </form>
//         </div>
//     </div>
// </div>
// <Footer />
// </div>