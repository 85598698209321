import React from 'react'
import Blogscard from './Blogscard'

const Blogs = () => {
    return (
        <div  className="bg-[#160F25] w-full md:py-6  xs:py-2 ">
            <div>
                <h1 className="lg:text-4xl font-bold dmd:text-3xl  sm:text-2xl  xs:text-lg   text-[#D94496] md:mb-0 xs:mb-4  xsm:text-lg  tracking-wide  w-4/5  md:w-3/5   xs:w-[90%] sm:w-4/5  sm:p-6  m-auto text-center font-poppins   "> Top Wellness Blogs: Explore the Latest Trends and Insights </h1>
            </div>
            <div>
                <Blogscard />

            </div>
        </div>
    )
}

export default Blogs