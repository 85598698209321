import React from 'react'
import trifitlogo from "../Assests/Images/trifitsmalllogo.svg"

const PrivacyPolicyAndCondition = () => {
    return (

        <div className=" z-[100]  bg-[#120426]">
            <div className='pt-6 pb-6 flex justify-center items-center'>
                <img className='bg-white w-16 rounded-full' src={trifitlogo} alt="Trifit" />
                <p className='text-white text-3xl ml-2 font-semibold font-poppins '>Trifit</p>
            </div>
            <div className="px-5 ">
                <h1 className="text-2xl font-bold text-white mb-2.5">Privacy Policy</h1>

                <p className="text-base text-white mb-5">
                    This Privacy Policy (“Policy”) explains how Svar Trifit Wellness Private Limited (hereinafter to be referred to as “Trifit”), and the Collaborators (identified in the Terms of Use) collect, store, use, transfer, and otherwise process your data (save and except non-personal data/information), for providing you the Platform and/or the Services (defined in the Terms of Use, available on the Platform) through the Platform, and shall be read with “Trifit User Terms and Conditions” available on the Trifit website.
                </p>

                <p className="text-base text-white mb-5">
                    IN ORDER TO FACILITATE AND PROVIDE YOU (AND CO-APPLICANTS, MEMBERS, NOMINEES, APPOINTEES, IF ANY) WITH THE SERVICES THROUGH THE PLATFORM, THE COMPANY, SVAR TRIFIT WELLNESS PRIVATE LIMITED NEED TO COLLECT VARIOUS DATA AND INFORMATION, BOTH PERSONAL AND SENSITIVE, FROM YOU WITH YOUR PRIOR AND EXPLICIT CONSENT.
                </p>

                <p className="text-base text-white mb-5">1. ACCEPTANCE OF THE PRIVACY POLICY:</p>

                <p className="text-base text-white font-bold mb-5">Consent:</p>

                <p className="text-base text-white mb-5">
                    By using this platform or its services/products, you are agreeing to our policy. This means you allow us and our partners to collect, keep, share, process, and store your personal and sensitive information as described in the policy. If you disagree with this policy, please do not proceed to use/access this platform or avail any services/products of the Trifit.
                </p>

                <p className="text-base text-white mb-5">
                    When you create a Trifit account or give us access to your personal information, you are agreeing to let us collect, process, store, use, and share your information according to this policy.
                </p>


                <p className="text-base text-white mb-5">
                    If you don't provide your personal information or decide to withdraw your consent for us to use it, we or our partners may choose not to give you the benefits you were expecting. This could mean not allowing you to use our platform or service.
                </p>

                <p className="text-base text-white mb-5">
                    If you withdraw your consent or delete your Trifit account, it will not change our or our collaborator's rights and obligations under the law. You understand and agree that we and our collaborators will keep collecting, using, processing, and storing your personal data as the law requires.
                </p>

                <p className="text-base text-white mb-5">
                    This policy applies only to your personal and sensitive personal data. Any non-personal information you provide to us, our collaborators, or our service providers, or that is collected by us, our collaborators, or our service providers, is not covered by this policy. Non-personal information will be used, shared, stored, and/or processed by us and/or our collaborators at our discretion, always subject to applicable laws. Non-personal data refers to information that does not directly identify you, is aggregated or de-personalized, and cannot establish an individual's specific identity.
                </p>

                <p className="text-base text-white mb-5">
                    "Sensitive information" is defined as the following:
                </p>

                <ul className="list-disc pl-5 text-white mb-5">
                    <li className="mb-2">Financial Information.</li>
                    <li className="mb-2">Protected health information (PHI).</li>
                    <li className="mb-2">Credential data.</li>
                    <li className="mb-2">Customer information.</li>
                    <li className="mb-2">Trade, proprietary information.</li>
                </ul>

                <p className="text-base text-white font-medium mb-5">
                    2. PERSONAL INFORMATION PROVIDED BY YOU AND/OR COLLECTED BY US AND/OR OUR COLLABORATORS:
                </p>

                <p className="text-base text-white mb-5">
                    We and the Collaborators will use the personal information including any sensitive personal data / information provided by You only in accordance with the purposes in this Policy or as per the requirements of the applicable laws.<br />
                    Except as provided herein or as clearly disclosed to You at the time of seeking or collection of sensitive personal data, we do not solicit any sensitive personal information about you.<br />
                    However, if you share such sensitive or personal information with us or the Collaborators voluntarily, we will not be liable for any actions, claims, costs, expenses or other liabilities that may arise because of any unauthorized use or misuse of such sensitive personal information. Any personal information including your sensitive personal information provided by you on the Platform or to us or to the Collaborators, will be deemed as an authorization given by you to us and the Collaborators to use, store, process, and share such personal information for the purpose as stated out on the Platform, the applicable laws.<br />
                    During your use of Platform, you may provide and/or we may collect and process such personal information from you, including but not limited to the below:
                </p>

                <p className="text-base text-white mb-5">
                    i. Personal Information provided by You:
                </p>

                <p className="text-lg text-white font-bold mb-5">
                    You may provide the personal information to us or our collaborators:
                </p>

                <p className="text-base text-white mb-5">
                    a) by filling in application forms or signing up for an account on the
                    Platform at the time of the onboarding process or for availing any
                    service on the Platform or any product thereto. This may include
                    contact information such as name, email address, mailing address,
                    phone number, KYC documents like Voter ID, PAN, Aadhaar card, Driving
                    License, etc.
                </p>

                <p className="text-base text-white mb-5">
                    b) voluntarily, when you call or write directly to us or to our
                    collaborators (including by e-mail, feedback and/or surveys).
                </p>

                <p className="text-base text-white mb-5">
                    c) You may be required to provide further information to us or the
                    collaborators for the purposes of availing our services. Such
                    additional information may include (without limitation) your health
                    and lifestyle-related data as prompted on the relevant page. You may
                    also be required to provide this information to us or the
                    collaborators via physical documents, e-mail, or other digital and
                    offline methods.
                </p>

                <p className="text-base text-white mb-5">
                    d) You agree to provide current, true, latest and correct information
                    and documents at any and all times, including for KYC or other
                    purposes. The said KYC details and other documents may be used by Us
                    or the collaborators or our affiliates to perform KYC, health or other
                    checks and extend the services to you. In the event of any changes to
                    any personal information or KYC documents or health/lifestyle
                    information in the KYC or other documents (as the case may be), you
                    will promptly notify us via email and/or update the details under Your
                    Account section immediately.
                </p>

                <p className="text-base text-white mb-5">
                    e) You also consent to the use, disclosure, and sharing of personal
                    information about You, including but not limited to information about
                    your health, physical attributes, medical history, and lifestyle, etc.
                    by Trifit Tech, the collaborators, Our affiliates and/or Our group
                    companies. This will help You avoid repeat queries/tests, and also
                    help You avail the additional products, services or other new
                    offerings by Trifit Tech or collaborators, Our affiliates and/or Our
                    group companies as per the terms of this Privacy Policy.
                </p>

                <p className="text-base text-white mb-5">
                    f) In the event of any changes to any personal information or KYC
                    documents or health/lifestyle information in the KYC or other
                    documents (as the case may be), You will promptly notify us via email
                    and/or update the details under “Your Account” section immediately.
                </p>

                <p className="text-lg text-white font-bold mb-5">
                    ii. Personal Information Collected from Third Parties:
                </p>

                <p className="text-base text-white mb-5">
                    a) We and/or collaborators may also be working closely with third
                    parties (including, for example, business partners, financial
                    institutions, technical sub-contractors, analytics providers, etc.)
                    and may lawfully receive information about you from such sources. Such
                    personal data may be shared internally and combined with data
                    collected on the Platform.
                </p>

                <p className="text-base text-white mb-5">
                    b) We and/or collaborators may also use the content you have shared
                    publicly, including on third party platforms or applications including
                    any social media channel, to promote our Services and/or Our Platform
                    and/or collaborators, or their services/platform (including by quoting
                    your content, reviews and/or recommendations, or displaying
                    screenshots of your content, reviews and/or recommendations).
                </p>

                <p className="text-lg text-white font-bold mb-5">
                    iii. Personal Information Collected by Us and/or the Collaborators:
                </p>

                <p className="text-base text-white mb-5">
                    a) We and the collaborators collect user account data which includes
                    both personal and non-personal data, including but not limited to the
                    email address, IP address, and name to log in to the Platform. This
                    information is required as part of the registration and onboarding
                    process to (i) create Your Trifit Account; (ii) provide our Service
                    and Platform; (iii) enable the collaborators to provide you with
                    products and/or services; (iv) perform verification and KYC checks on
                    you; (and it is also used to auto-populate relevant fields when you
                    apply for any product or service.
                </p>

                <p className="text-base text-white mb-5">
                    b) We and the collaborators may also collect mobile numbers for
                    verification to check the active SIM status on your device, uniquely
                    identify you and prevent fraud/unauthorized access. When you grant us
                    access to the address book on your mobile device, then we and the
                    collaborators access the names and contact information from your
                    address book to facilitate invitations and to assess your phone usage
                    and habits.
                </p>

                {/* Repeat header */}
                <p className="text-lg text-white font-bold mb-5">
                    iii. Personal Information Collected by Us and/or the Collaborators:
                </p>


                <p className="text-base text-white mb-5">
                    c) As part of your journey on the Platform, we access your phonebook
                    contacts which includes their contact names, phone numbers, account
                    types, favorites (starred) and contact labels to enrich your profile.
                </p>

                <p className="text-base text-white mb-5">
                    d) We and the collaborators shall collect transaction information
                    related to the use of our Services, the Platform and/or the products
                    and services, including the type of Services requested, the date and
                    time the Service was provided, and payment method.
                </p>

                <p className="text-base text-white mb-5">
                    e) Additionally, if someone uses your promotion code, we or the
                    collaborators may associate your name with that person and their usage
                    of the Platform/Services.
                </p>

                <p className="text-base text-white mb-5">
                    f) If you call our call centers, we or the collaborators may record
                    information provided by you to service you as per the Terms of Use and
                    this Policy, including for quality and training purposes.
                </p>

                <p className="text-base text-white mb-5">
                    g) When you grant us access to the SMSs on your mobile device, then we
                    and our collaborators collect financial SMS data stored on your mobile
                    or other device. We and the collaborators use this data to analyze
                    your financial profile and enable the provisioning of the best offers
                    by us and/or collaborators with respect to the Platform, Services
                    and/or products offered by us.
                </p>

                <p className="text-base text-white mb-5">
                    h) We may collect non-personal data as specified in the section below.
                </p>

                <p className="text-lg text-white font-bold mb-5">
                    3. NON-PERSONAL INFORMATION / DATA
                </p>

                <p className="text-base text-white mb-5">
                    i. “Non-Personal Information” means and includes any information that
                    does not reveal Your specific identity, such as browser information,
                    information collected through Cookies (as defined below), pixel tags
                    and other technologies, demographic information, etc. Our Platform
                    gathers some information automatically when You visit the Platform or
                    avail any Service and stores it in your log files.
                </p>

                <p className="text-base text-white mb-5">
                    ii. We and the collaborators may collect certain non-personal
                    information about Your computer or other electronic device through
                    which you have accessed Platform or our Services or the
                    products/services of collaborators to facilitate, evaluate, and verify
                    your use of Platform or Your Trifit Account or your
                    interactions/communications with the collaborators.
                </p>


                <p className="text-base text-white mb-5">
                    iii. We and the collaborators collect non-personal data about the
                    devices used to access our/their Services, including the hardware
                    models, device IP address, operating systems and versions, software,
                    preferred languages, unique device identifiers, advertising
                    identifiers, serial numbers, device motion data, and mobile network
                    data.
                </p>

                <p className="text-base text-white mb-5">
                    iv. We collect non-personal data about how you interact with our
                    Services, Platform and/or with the collaborators. This includes data
                    such as access dates and times, features or pages viewed, Platform
                    crashes and other system activity, type of browser, and third-party
                    sites or services used before or in the course of interacting with our
                    Services and/or the services/products.
                </p>

                <p className="text-base text-white mb-5">
                    v. “Cookies” are small files that reside on Your computer's/
                    electronic device hard drive and generally contain an anonymous unique
                    identifier and are accessible only by Platform that placed them there
                    and not any other sites. You may refuse to accept Cookies by
                    activating the setting on Your browser which allows You to refuse the
                    setting of Cookies. However, if You do not provide such information,
                    you may be unable to access certain parts of the Platform. Unless You
                    have adjusted Your browser setting so that it will refuse cookies, our
                    system may issue Cookies when You log on to Platform. The use of
                    Cookies by Our partners, advertisers or service providers is not
                    covered by this Policy.
                </p>

                <p className="text-base text-white mb-5">
                    vi. We collect the location data from you in two ways: (a) when you
                    add the pin code as part of your account creation process; and (b)
                    from your mobile device when enabled by you to do so.
                </p>

                <p className="text-base text-white mb-5">
                    vii. We collect the data about your installed applications, including
                    the applications that you have installed on and from the date you
                    create your account on the Platform and the manner in which you use
                    them.
                </p>

                <p className="text-base text-white mb-5">
                    viii. IT IS REITERATED THAT THIS POLICY DOES NOT APPLY TO NON-PERSONAL
                    DATA / INFORMATION INCLUDING PROCESSING OF ANY DATA IN ANONYMISED,
                    AGGREGATED OR DE-IDENTIFIED FORM AVAILABLE WITH US OR COLLECTED BY US
                    OR PROVIDED TO US / THE COLLABORATORS. THE USE OF SUCH NON-PERSONAL
                    DATA / INFORMATION (INCLUDING RETENTION, SHARING, AND PROTECTION)
                    SHALL BE AT OUR / THE COLLABORATORS SOLE DISCRETION AND REQUIREMENTS.
                </p>

                <p className="text-lg text-white font-bold mb-5">
                    iii. Personal Information Collected by Us and/or the Collaborators:
                </p>

                <p className="text-base text-white mb-5">
                    a) We and the collaborators collect user account data which includes
                    both personal and non-personal data, including but not limited to the
                    email address, IP address, and name to log in to the Platform. This
                    information is required as part of the registration and on-boarding
                    process to (i) create Your Trifit Account; (ii) provide our Service
                    and Platform; (iii) enable the collaborators to provide you with
                    products and/or services; (iv) perform verification and KYC checks on
                    you; and (v) it is also used to auto-populate relevant fields when you
                    apply for any product or service.
                </p>

                <p className="text-base text-white mb-5">
                    b) We and the collaborators may also collect mobile numbers for
                    verification to check the active SIM status on your device, uniquely
                    identify you, and prevent fraud and unauthorized access. When you
                    grant us access to the address book on your mobile device, we and the
                    collaborators access the names and contact information from your
                    address book to facilitate invitations and to assess your phone usage
                    and habits.
                </p>

                <p className="text-base text-white mb-5">
                    c) As part of your journey on the Platform, we access your phonebook
                    contacts which includes their contact names, phone numbers, account
                    types, favorites (starred) and contact labels to enrich your profile.
                </p>

                <p className="text-base text-white mb-5">
                    d) We and the collaborators shall collect transaction information
                    related to the use of our Services, the Platform and/or the products
                    and services, including the type of Services requested, the date and
                    time the Service was provided, and payment method.
                </p>

                <p className="text-base text-white mb-5">
                    e) Additionally, if someone uses your promotion code, we or the
                    collaborators may associate your name with that person and their usage
                    of the Platform/Services.
                </p>

                <p className="text-base text-white mb-5">
                    f) If you call our call centers, we or the collaborators may record
                    information provided by you to service you as per the Terms of Use and
                    this Policy, including for quality and training purposes.
                </p>

                <p className="text-base text-white mb-5">
                    g) When you grant us access to the SMSs on your mobile device, then we
                    and our collaborators collect financial SMS data stored on your mobile
                    or other device. We and the collaborators use this data to analyze
                    your financial profile and enable the provisioning of the best offers
                    by us and/or collaborators with respect to the Platform, Services
                    and/or products offered by us.
                </p>

                <p className="text-base text-white mb-5">
                    h) We may collect non-personal data as specified in the section below.
                </p>

                <p className="text-lg font-bold text-white mb-5">
                    3. NON-PERSONAL INFORMATION / DATA
                </p>

                <p className="text-base text-white mb-5">
                    i. “Non-Personal Information” means and includes any information that
                    does not reveal Your specific identity, such as browser information,
                    information collected through Cookies (as defined below), pixel tags,
                    and other technologies, demographic information, etc. Our Platform
                    gathers some information automatically when You visit the Platform or
                    avail any Service and stores it in your log files.
                    <br /><br />
                    ii. We and the collaborators may collect certain non-personal
                    information about Your computer or other electronic device through
                    which you have accessed the Platform or our Services or the
                    products/services of collaborators to facilitate, evaluate, and verify
                    your use of the Platform or Your Trifit Account or your
                    interactions/communications with the collaborators.
                    <br /><br />
                    iii. We and the collaborators collect non-personal data about the
                    devices used to access our/their Services, including the hardware
                    models, device IP address, operating systems and versions, software,
                    preferred languages, unique device identifiers, advertising
                    identifiers, serial numbers, device motion data, and mobile network
                    data.
                </p>

                <p className="text-base text-white mb-5">
                    iv. We collect non-personal data about how you interact with our
                    Services, Platform and/or with the collaborators. This includes data
                    such as access dates and times, features or pages viewed, Platform
                    crashes and other system activity, type of browser, and third-party
                    sites or services used before or in the course of interacting with our
                    Services and/or the services/products.
                    <br /><br />
                    v. “Cookies” are small files that reside on Your computer's/electronic
                    device hard drive and generally contain an anonymous unique identifier
                    and are accessible only by Platform that placed them there and not any
                    other sites. You may refuse to accept Cookies by activating the
                    setting on Your browser which allows You to refuse the setting of
                    Cookies. However, if You do not provide such information, you may be
                    unable to access certain parts of the Platform. Unless You have
                    adjusted Your browser setting so that it will refuse cookies, our
                    system may issue Cookies when You log on to the Platform. The use of
                    Cookies by Our partners, advertisers, or service providers is not
                    covered by this Policy.
                    <br /><br />
                    vi. We collect the location data from you in two ways: (a) when you
                    add the pin code as part of your account creation process; and (b)
                    from your mobile device when enabled by you to do so.
                    <br /><br />
                    vii. We collect the data about your installed applications, including
                    the applications that you have installed on and from the date you
                    create your account on the Platform and the manner in which you use
                    them.
                </p>

                <p className="text-base text-white mb-5">
                    viii. IT IS REITERATED THAT THIS POLICY DOES NOT APPLY TO NON-PERSONAL
                    DATA / INFORMATION INCLUDING PROCESSING OF ANY DATA IN ANONYMISED,
                    AGGREGATED OR DE-IDENTIFIED FORM AVAILABLE WITH US OR COLLECTED BY US
                    OR PROVIDED TO US / THE COLLABORATORS. THE USE OF SUCH NON-PERSONAL
                    DATA / INFORMATION (INCLUDING RETENTION, SHARING, AND PROTECTION)
                    SHALL BE AT OUR / THE COLLABORATORS' SOLE DISCRETION AND REQUIREMENTS.
                </p>

                <p className="text-lg font-bold text-white mb-5">
                    4. How We / Our Collaborators Use Your Personal Information
                </p>

                <p className="text-base text-white mb-5">
                    We and/or the collaborators may use, process, and share your personal
                    information including any sensitive personal information/data for:
                </p>

                <p className="text-base text-white mb-5">
                    We and/or the collaborators may use, process, and share your personal
                    information including any sensitive personal information/data for:
                    <br /><br />
                    i. Enabling the Platform and the Services offered by us and/or the
                    collaborators, including:
                    <br /><br />
                    a) provide and improve the Platform and/or the Services.
                    <br /><br />
                    b) to enable the provisioning of products/services by the
                    collaborators including but not limited to personalizing user
                    experience and sending you information and updates about new products,
                    your membership, your plan expiry dates, etc. This could include
                    displaying appropriate content based on our / collaborators’ judgment
                    and discretion.
                </p>

                <p className="text-base text-white mb-5">
                    g) When you grant us access to the SMSs on your mobile device, then we
                    and our collaborators collect financial SMS data stored on your mobile
                    or other device. We and the collaborators use this data to analyze
                    your financial profile and enable the provisioning of the best offers
                    by us and/or collaborators with respect to the Platform, Services
                    and/or products offered by us.
                </p>

                <p className="text-base text-white mb-5">
                    h) We may collect non-personal data as specified in the section below.
                </p>

                <p className="text-lg font-bold text-white mb-5">
                    3. NON-PERSONAL INFORMATION / DATA
                </p>

                <p className="text-base text-white mb-5">
                    i. “Non-Personal Information” means and includes any information that
                    does not reveal Your specific identity, such as browser information,
                    information collected through Cookies (as defined below), pixel tags,
                    and other technologies, demographic information, etc. Our Platform
                    gathers some information automatically when You visit the Platform or
                    avail any Service and stores it in your log files.
                    <br /><br />
                    ii. We and the collaborators may collect certain non-personal
                    information about Your computer or other electronic device through
                    which you have accessed the Platform or our Services or the
                    products/services of collaborators to facilitate, evaluate, and verify
                    your use of the Platform or Your Trifit Account or your
                    interactions/communications with the collaborators.
                    <br /><br />
                    iii. We and the collaborators collect non-personal data about the
                    devices used to access our/their Services, including the hardware
                    models, device IP address, operating systems and versions, software,
                    preferred languages, unique device identifiers, advertising
                    identifiers, serial numbers, device motion data, and mobile network
                    data.
                </p>

                <p className="text-base text-white mb-5">
                    iv. We collect non-personal data about how you interact with our
                    Services, Platform and/or with the collaborators. This includes data
                    such as access dates and times, features or pages viewed, Platform
                    crashes and other system activity, type of browser, and third-party
                    sites or services used before or in the course of interacting with our
                    Services and/or the services/products.
                    <br /><br />
                    v. “Cookies” are small files that reside on Your computer's/electronic
                    device hard drive and generally contain an anonymous unique identifier
                    and are accessible only by Platform that placed them there and not any
                    other sites. You may refuse to accept Cookies by activating the
                    setting on Your browser which allows You to refuse the setting of
                    Cookies. However, if You do not provide such information, you may be
                    unable to access certain parts of the Platform. Unless You have
                    adjusted Your browser setting so that it will refuse cookies, our
                    system may issue Cookies when You log on to the Platform. The use of
                    Cookies by Our partners, advertisers, or service providers is not
                    covered by this Policy.
                    <br /><br />
                    vi. We collect the location data from you in two ways: (a) when you
                    add the pin code as part of your account creation process; and (b)
                    from your mobile device when enabled by you to do so.
                    <br /><br />
                    vii. We collect the data about your installed applications, including
                    the applications that you have installed on and from the date you
                    create your account on the Platform and the manner in which you use
                    them.
                </p>

                <p className="text-base text-white mb-5">
                    viii. IT IS REITERATED THAT THIS POLICY DOES NOT APPLY TO NON-PERSONAL
                    DATA / INFORMATION INCLUDING PROCESSING OF ANY DATA IN ANONYMISED,
                    AGGREGATED OR DE-IDENTIFIED FORM AVAILABLE WITH US OR COLLECTED BY US
                    OR PROVIDED TO US / THE COLLABORATORS. THE USE OF SUCH NON-PERSONAL
                    DATA / INFORMATION (INCLUDING RETENTION, SHARING, AND PROTECTION)
                    SHALL BE AT OUR / THE COLLABORATORS' SOLE DISCRETION AND REQUIREMENTS.
                </p>

                <p className="text-lg font-bold text-white mb-5">
                    4. How We / Our Collaborators Use Your Personal Information
                </p>

                <p className="text-base text-white mb-5">
                    We and/or the collaborators may use, process, and share your personal
                    information including any sensitive personal information/data for:
                    <br />
                    <br />
                    i. Enabling the Platform and the Services offered by us and/or the
                    collaborators, including:
                    <br />
                    <br />
                    a) provide and improve the Platform and/or the Services.
                    <br />
                    <br />
                    b) to enable the provisioning of products/services by the
                    collaborators including but not limited to personalizing user
                    experience and sending you information and updates about new products,
                    your membership, your plan expiry dates, etc. This could include
                    displaying appropriate content based on our / collaborators’ judgment
                    and discretion.
                    <br />
                    <br />
                    c) perform internal operations necessary to provide our Services or
                    the Platform including enabling us to provide you with all the
                    relevant details with respect to plans in an easy viewing format on
                    Your Trifit Account, or to enable the collaborators to provide the
                    products and/or services to you including updates on your plan through
                    Your Trifit Account or otherwise, including to troubleshoot software
                    bugs, operational problems or to conduct testing.
                    <br />
                    <br />
                    d) providing details with respect to your plans on Your Trifit
                    Account.
                    <br />
                    <br />
                    e) the collaborators shall share your plan-related data with
                    collaborators Tech to enable You to view and access the details with
                    respect to your plans through Your Trifit Account, and you hereby
                    provide your explicit consent for the same.
                    <br />
                    <br />
                    f) to help avoid repeat tests or checks or questions to and by You,
                    and to allow You to avail the additional products, services, or other
                    new offerings by Us, the collaborators, our affiliates and/or our
                    group companies.
                    <br />
                    <br />
                    ii. to enable Us, Our affiliates and group companies to contact You or
                    otherwise communicate with You to offer new products, services,
                    facilities, features, and/or discounts as the case may be.
                    <br />
                    <br />
                    iii. Enabling customer support including to help us / collaborators
                    respond to your pre-sale and post-sale requests and support needs,
                    efficiently and without delay, including through Your Trifit Account
                    or otherwise.
                </p>
                <div className="text-white font-poppins text-base mb-5">
                    We and/or the collaborators may use, process, and share your personal
                    information including any sensitive personal information/data for:
                    <br />
                    <br />
                    iv. Research, development, improvements and innovation including:
                    <br />
                    <br />
                    a) to conduct research and analysis to develop new products/services,
                    improve our / collaborators services, or to develop features and
                    functionality to detect, prevent, mitigate, and investigate fraudulent
                    or illegal activities.
                    <br />
                    <br />
                    b) analyze how the Platform and/or the Services are used, diagnose
                    service or technical problems, and maintain security for us and the
                    collaborators.
                    <br />
                    <br />
                    c) help you efficiently use and access the Platform or the Services or
                    any part thereof; or any products/services offered by the
                    collaborators through Your Trifit Account.
                    <br />
                    <br />
                    d) to assess the serviceability of services, products, or features
                    offered by us or the collaborators or any other affiliate/group
                    company of Trifit Tech, and to send you communications with respect to
                    the same.
                    <br />
                    <br />
                    e) to contact you for refund or cancellation or confirmation of a
                    purchase or failed transaction on the Platform or with the
                    collaborators including to display the status of your payments,
                    applications, inquiries and/or plans on Your Trifit Account.
                    <br />
                    <br />
                    f) to look at trends and customer interests with respect to our
                    Services, and/or the products/services of the collaborators, whether
                    offered now or in the future. We and/or the collaborators may combine
                    the information we / collaborators receive from you, with the
                    information we / collaborators get from other third parties including
                    any of the service providers.
                </div>
                <div className="text-white font-poppins text-base mb-5">
                    We and/or the collaborators may use, process, and share your personal
                    information including any sensitive personal information/data for:
                    <br />
                    <br />
                    g) Enabling communications through Your Trifit Account or otherwise,
                    including for enabling marketing and outreach activities including
                    promotion of new products, services, and/or platforms offered by us or
                    the collaborators or our group companies / other affiliates, and for
                    automated/automating decisions. We and the collaborators will use and
                    share your personal information to send you transactional
                    communications.
                    <br />
                    We or the collaborators may send you emails or SMS/es or other push
                    notifications about Your Trifit Account or your expression of interest
                    or purchases.
                    <br />
                    <br />
                    h) Marketing and outreach including informing you about the new
                    products offered by us, the plans or any other group company. You may
                    have an interest or intention of availing services or a general query
                    with respect to the services available on Platform and/or through
                    Platform and/or by the collaborators. Accordingly, you may contact us;
                    or we or the collaborators may contact you. By contacting us / the
                    collaborators in any way and/or by providing us / the collaborators
                    your phone number and/or contact details and/or other details or by
                    showing interest in any product/service of the Company or the
                    collaborators or any other affiliate/group company, you thereby give
                    us, the collaborators and/or other affiliates the unequivocal right
                    and consent to use the said personal data as per this Policy.
                </div>
                <div className="text-white font-poppins text-base mb-5">
                    We and/or the collaborators may use, process, and share your personal
                    information including any sensitive personal information/data for:
                    <br />
                    <br />
                    v) Ensure compliance under applicable laws including:
                    <br />
                    <br />
                    a) to enable us or the collaborators to comply with any legal and
                    regulatory obligations or requisition requests by any authority under
                    the applicable laws including to enable us or the collaborators to
                    proactively communicate with the authorities, or law enforcement
                    agencies or any other government or judicial body in connection with
                    any suspicious, illegal, fraudulent or unlawful activities.
                    <br />
                    <br />
                    b) to enforce our / the collaborators legal or other rights against
                    you including to comply with our / collaborators obligations to you.
                    <br />
                    <br />

                    <h2 className="font-bold text-lg mb-3">
                        5. Sharing of Your Personal Information
                    </h2>

                    i. With Our other affiliates and/or our group companies: We may also
                    share the personal information or details provided by you or collected
                    by us with our other affiliates or members of our group companies for
                    the purposes specified under Point 4 (How We Use Your Personal Data)
                    of this Policy. We reserve the right to exchange, share or provide the
                    personal information relating to your personal, financial details and
                    other sensitive information with our affiliates and other group
                    companies as required, and you will not hold us/our affiliates and/or
                    our any other group companies and their agents liable for use,
                    processing, retention or sharing of the personal information for use
                    as per Point 4 (How We Use Your Personal Data) of this Policy. This
                    right is in addition to the rights of the Trifit/ Collaborator to
                    access, use, store, and share your personal data as per Point 4 (How
                    We Use Your Personal Data).
                    <br />
                    <br />
                    YOU UNDERSTAND AND AGREE THAT WE AND THE COLLABORATORS MAY DISCLOSE
                    YOUR PERSONAL INFORMATION, TO ANY MEMBER OF OUR GROUP OR OUR
                    AFFILIATES OR A RELATED PARTY, FOR THE PURPOSES SPECIFIED UNDER
                    SECTION 4 (HOW WE USE YOUR PERSONAL DATA) TO THIS POLICY, AND YOU
                    PROVIDE YOUR EXPRESS AND UNCONDITIONAL CONSENT FOR THE SAME.
                    <br />
                    <br />
                    ii. With Third Party Service Provider/s: We or the collaborators or
                    our other affiliates may use third-party service providers to help us
                    operate our business, perform KYC checks, manage the Platform, provide
                    the Services, or administer activities on our, collaborators, or our
                    other affiliates’ behalf, such as sending out newsletters, surveys, or
                    questionnaires, or conducting other marketing activities with respect
                    to us, the collaborators or our other affiliates’ products/services,
                    including any new offerings. We may disclose your personal information
                    to third parties (including any foreign third parties to the extent
                    permitted by the applicable laws) for the purposes mentioned under
                    Section 4 (How We Use Your Personal Data) to this Policy, inter alia,
                    in order to perform KYC checks, provide You with customer support
                    facilities, to comply with our/collaborators legal obligations
                    including prevention of money laundering or otherwise as prescribed by
                    the relevant authorities or under any applicable laws, to enforce the
                    Terms of Use, to facilitate marketing and advertising activities for
                    us, collaborators and/or our other affiliates, to conduct analysis, to
                    provide You services, to facilitate services for You, to send
                    notifications, reminders or to prevent, detect, mitigate, and
                    investigate fraudulent or illegal activities related to Platform or
                    the products/services of the collaborators and to avail services from
                    third parties for data analysis and market research. Some of these
                    functionalities may include analysing customer behavior, conducting
                    research into customer behavior and contacting the customers for the
                    said purpose, and to automate our marketing and outreach efforts,
                    detection and flagging of fraud, cloud services, validating and
                    authenticating your employment status, employment information, and
                    employment duration.
                </div>
                <div className="text-white font-poppins text-base mb-5">
                    iii. With Authorities or the Government: We may disclose or share your
                    personal information if we are under a duty to comply with any legal
                    obligation or an order from the government and/or a statutory
                    authority, or in order to enforce or apply the Terms of Use or assign
                    such personal information during corporate divestitures, mergers, or
                    to protect the rights, property, or safety of the Company, the Users,
                    or others. This includes exchanging personal information with other
                    companies and organizations for the purposes of fraud protection and
                    credit risk reduction.
                    <br />
                    <br />
                    iv. For Business Requirements: In the event the Company is merged with
                    or acquired by another company or in case of re-organization or
                    re-structuring of business, we, the collaborators or our other
                    affiliates may share your personal information, wholly or in part,
                    with another business entity/ies.
                    <br />
                    <br />

                    <h2 className="font-bold text-lg mb-3">6. Retention Policy</h2>

                    We and the collaborators collect, use, share and retain your personal
                    information only for the purpose specified in the preceding sections
                    above, including ensuring compliance with applicable laws. Subject to
                    regulatory or law enforcement requirements or other obligations under
                    applicable laws which may prescribe longer periods, we will retain
                    your personal information in identifiable form until you withdraw your
                    consent. To withdraw your consent, you may write to the grievance
                    officer as per the details mentioned in the “Contact Us” section.
                    <br />
                    <br />

                    <h2 className="font-bold text-lg mb-3">
                        7. How Trifit Protects Your Information
                    </h2>

                    We, at Trifit, have appropriate data collection, storage, and
                    processing practices. Our and the collaborators' security measures
                    protect against unauthorized access, alteration, disclosure or
                    destruction of your personal information. All kinds of private
                    information exchange and transactions on the Platform happen through
                    secured communication channels and are encrypted.
                    <br />
                    <br />

                    We or the collaborators assume no liability for any disclosure of
                    personal information due to errors in transmission, unauthorized
                    third-party access, or other acts of third parties, or acts or
                    omissions beyond our / collaborators' reasonable control and you agree
                    that you will not hold us / collaborators responsible for any breach
                    of security or unauthorized disclosure. In using the Platform and/or
                    availing the services or products of the collaborators, you accept the
                    inherent security implications of data transmission over the internet,
                    and the World Wide Web cannot always be guaranteed as completely
                    secure. Therefore, your use of the Platform including availing any
                    products/services offered by us/ the collaborators will be at your own
                    risk.
                </div>
                <div className="text-white font-poppins text-base mb-5">
                    <h2 className="font-bold text-lg mb-3">8. Third Party Websites</h2>
                    <p>
                        While using the Platform, you may find content that contains links to
                        other websites or portals on the internet. These sites and services
                        may have their own privacy policies. Browsing on and interacting with
                        any other website, including the websites which contain a link to our
                        website, is subject to that website’s own terms and conditions.
                    </p>
                    <br />

                    <h2 className="font-bold text-lg mb-3">
                        9. Changes in this Privacy Policy and Other Terms
                    </h2>
                    <p>
                        We and the collaborators have taken every effort to ensure that this
                        Policy adheres to the applicable laws. The invalidity or
                        unenforceability of any part of this Policy shall not prejudice or
                        affect the validity or enforceability of the remainder of this Policy.
                        This Policy does not apply to any information other than the personal
                        including sensitive information and does not extend to any
                        non-personal data provided by you or collected by us / our
                        collaborators / other affiliates / by our service providers.
                    </p>
                    <br />
                    <p>
                        This Policy shall be inapplicable to any unsolicited information you
                        provide us through the Platform or otherwise. This includes but is not
                        limited to, information posted in any public areas of the Platform.
                        All unsolicited information shall be deemed to be non-confidential,
                        and the Company shall be free to use and/or disclose such unsolicited
                        information without any limitations. We and the collaborators reserve
                        the right to update this Policy at any time, so you are advised to
                        review this Policy statement frequently. Your access to or continued
                        use of the Platform after the changes have been posted on this page
                        shall confirm your unequivocal acceptance of the revised privacy policy.
                    </p>
                    <br />

                    <h2 className="font-bold text-lg mb-3">10. Contact Us</h2>
                    <p>
                        If you have any questions, comments, or grievances in relation to our
                        privacy policy as outlined above, you may write to us at:
                    </p>
                    <br />
                    <h2 className="font-bold text-lg mb-3">
                        Svar Trifit Wellness Private Limited
                    </h2>
                    <h2 className="font-bold text-lg mb-3">Mb. No</h2>
                    <h2 className="font-bold text-lg mb-3">Email ID:</h2>

                    <h1 className="font-bold font-poppins text-2xl mb-3">TRIFIT USER TERMS AND CONDITIONS</h1>
                    <p>
                        These terms and conditions (“User Terms”) read with the “Privacy
                        Policy” available on the Site/Application constitute a legally binding
                        agreement (“Agreement”) between You and M/s Svar Trifit Wellness
                        Private Limited (hereinafter to be referred to as “Trifit”) and shall
                        apply to and govern your visit to and use of the Site/Application
                        (whether in the capacity of a user or a Practitioner) and any of its
                        products or services through any electronic device whether it be a
                        computer or a mobile phone as well as to all information,
                        recommendations and/or Services provided to You on or through the
                        Site/Application.
                    </p>
                </div>
                <div className="text-white font-poppins  text-base mb-5">
                    <p>
                        This agreement explains the rules for using the Site/Application and
                        how we will manage your account while you are a registered member.
                        These rules apply to the services provided by Trifit on the
                        Site/Application, including the products offered and the services
                        rendered by listed Practitioners to various users, including medical
                        practitioners and other visitors. We may use "cookies" or similar
                        files to store information on a user's computer to customize the
                        Site/Application based on the user's preferences. While we try to make
                        sure that all information provided on the Site/Application about
                        policies, products, and services is accurate and up to date, we cannot
                        guarantee the timeliness, accuracy, completeness, reliability, or
                        content of the information. Visitors and customers are advised to
                        verify the accuracy of the information with Trifit.
                    </p>
                    <br />

                    <p>
                        Trifit’s performance to this Agreement is subject to prevailing Indian
                        laws and legal processes, and nothing contained in this Agreement is
                        in derogation of Trifit’s right to comply with Governmental, Court,
                        and law enforcement agencies directions relating to the use of this
                        Site/Application or information provided to/gathered by Trifit
                        vis-à-vis such use.
                    </p>
                    <br />

                    <p>
                        Trifit may introduce new services concerning the Site/Application from
                        time to time. The existence and availability of the new services will
                        be notified on the Site/Application as and when they become available
                        and any revisions in the Terms & Conditions will take place in
                        accordance with these Terms & Conditions. In addition, Trifit may also
                        publish notices of general nature, which are applicable to all
                        visitors or Customers in a newspaper or on its Site/Application. Such
                        notices will have the same effect as a notice served individually to
                        each visitor or Customer.
                    </p>
                    <br />

                    <p>
                        This document is an electronic record in terms of the Information
                        Technology Act, 2000 and rules thereunder as applicable and the
                        provisions pertaining to electronic records in various statutes as
                        amended from time to time. This electronic record is generated by a
                        computer system and does not require any physical or digital
                        signatures.
                    </p>
                    <br />

                    <h2 className="font-poppins text-lg mb-3">CONSENT:</h2>
                    <p>
                        When you use this Site/Application, you agree to follow these User
                        Terms and the Privacy Policy. Please read and understand all the User
                        Terms before using the Site/Application. If you don't accept any of
                        the User Terms, then don't use the Site/Application or its services.
                        Accepting the User Terms also means accepting the Privacy Policy
                        available at the Site/Application.
                    </p>
                    <br />

                    <h2 className="font-poppins text-lg mb-3">1. DEFINITIONS</h2>
                    <p>
                        All of the defined and capitalized terms in these User Terms will have
                        the meaning assigned to them here below:
                    </p>
                    <br />

                    <p>
                        (i) “Account” shall mean the account created by the Customer on the
                        Application for availing the Services provided/facilitated by Trifit.
                    </p>
                    <br />

                    <p>
                        (ii) “Applicable Laws” shall mean and include all applicable Indian
                        laws, Ordinances, Rules, by-laws, regulations, notifications,
                        guidelines, policies, directions, directives, and orders of any
                        governmental authority, tribunal, or Court of India.
                    </p>
                    <br />

                    <p>
                        (iii) “Application” shall mean our mobile application and/or website
                        as updated by Trifit from time to time.
                    </p>
                    <br />

                    <div className="flex flex-col bg-[#120426] p-5">
                        <p className="text-white font-poppins mb-5">
                            (v) “Customer/ You/User” shall mean and include:
                        </p>
                        <p className="text-white font-poppins mb-5">
                            ● A medical practitioner or healthcare/wellness provider (whether an
                            individual professional or an organization) or similar institution
                            wishing to be listed, or already listed, and providing healthcare
                            services, on the Site/Application, including designated, authorized
                            associates of such practitioners or institutions; and any individual
                            wishes to avail the services of Trifit (“practitioners(s)”, “you” or
                            “User”); or
                        </p>
                        <p className="text-white font-poppins mb-5">
                            ● A user, availing this service on his/her consent, being or not being
                            sponsored/promoted by any organization/body to utilize the services
                            through the Site/Application, his/her representatives or affiliates,
                            searching for availing health and wellness services through the
                            Site/Application; or.
                        </p>
                        <p className="text-white font-poppins mb-5">
                            ● Otherwise a user of the Site/Application who has an Account on the
                            Site/Application (“End User”).
                        </p>
                        <p className="text-white font-poppins mb-5">
                            (vi) “Force Majeure Event” shall mean any and all events arising due
                            to any cause beyond the reasonable control of Trifit.
                        </p>
                        <p className="text-white font-poppins mb-5">
                            (vii) “Trifit” or “us” or “we” or “our” shall mean Svar Trifit
                            Wellness Private Limited, a company incorporated under the provisions
                            of the Companies Act, 2013 and having its registered Address at New No
                            59, Old No 120, Residency Road, Richmond Town, Bangalore, Bangalore
                            North, Karnataka, India, 560025, which expression shall, unless it be
                            repugnant to the context or meaning thereof, be deemed to mean and
                            include all its successors, affiliates and permitted assigns.
                        </p>
                        <p className="text-white font-poppins mb-5">
                            (viii) “Registration Data” shall mean and may include the present,
                            valid, true, and accurate name, email ID, phone number, and such other
                            information as may be required by Trifit from the Customer from time
                            to time for registration on the Application and to provide its
                            services.
                        </p>
                        <p className="text-white font-poppins mb-5">
                            (ix) “Services” shall mean the various categories of Services
                            including the following made available through the Site/Application:
                        </p>
                        <p className="text-white font-poppins mb-5">b) a) For Practitioners:</p>
                        <p className="text-white font-poppins mb-5">
                            Outreach of Practitioners and their profiles along with options made
                            available to the other Users and visitors of the Site/Application for
                            its usage/ teleconsultations;
                        </p>
                        <p className="text-white font-poppins mb-5">b) For other Users:</p>
                        <p className="text-white font-poppins mb-5">Facility to:</p>
                        <p className="text-white font-poppins mb-5">
                            1. Create and maintain ‘Health Accounts’ and upload claim requests to
                            track the progress of the same;
                        </p>
                        <p className="text-white font-poppins mb-5">
                            2. Search for Practitioners by specialty, services offered, or any
                            other criteria that may be developed and made available by Trifit;
                        </p>
                        <p className="text-white font-poppins mb-5">
                            3. Make appointments with Practitioners;
                        </p>
                    </div>


                </div>
                <div className="flex flex-col bg-[#120426] p-5">
                    <p className="text-white font-poppins mb-5">
                        4. Interact with healthcare chatbot; and
                    </p>
                    <p className="text-white font-poppins mb-5">
                        5. Avail of other services offered by Trifit and its partners, like but not limited to, medicine delivery, diagnostics, home-healthcare, track fitness activities and health vitals, etc.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        6. Insurance-related services/consulting services.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        (xviii) “Site” shall mean the Application and the website operated by Trifit or any other software that enables the use of the Site/Application or such other URL as may be specifically provided by Trifit. However, Trifit does not own any responsibility for the businesses housed/advertised on its site; the users shall ensure the credibility of such businesses immediately at the time of being redirected to their concerned website/Application.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        (xviii) “T&Cs” and “User Terms” shall mean these Customer terms and conditions.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        (xix) “Privacy Policy” shall mean the policy mentioned on the website of Trifit and may be updated from time to time.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        You will be “Eligible” to use the Services only when you fulfill all of the following conditions:
                    </p>
                    <p className="text-white font-poppins mb-5">
                        I. You have attained at least 18 (eighteen) years of age.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        II. You are competent to enter a contract under the Applicable Laws.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        III. You should be registered as a registered practitioner with the applicable governing body in the case of Medical Practitioners/coaches, etc.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        IV. If you reside in a jurisdiction that restricts the use of the Service because of age, or restricts the ability to enter into contracts such as these User Terms due to age, you must abide by such age limits.
                    </p>
                    <p className="text-white font-poppins mb-5 font-bold">
                        3. REGISTRATION/MEMBERSHIP AND MANNER OF USAGE OF ACCOUNT
                    </p>
                    <p className="text-white font-poppins mb-5">
                        You understand and acknowledge that You can register on the Site only after complying with the requirements of Clause 2 and by entering Your Registration Data. You shall ensure that the Registration Data you provide is accurate, complete, current, valid, and true and is updated from time to time. We shall bear no liability for false, incomplete, old or incorrect Registration Data provided by You.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        You are solely responsible for maintaining the confidentiality of Your Registration Data and will be liable for all activities and transactions that occur through Your Account, whether initiated by You or any third party. Your Account cannot be transferred, assigned or sold to a third party. We shall not be liable for any loss that You may incur as a result of someone else using Your password or Account, either with or without Your knowledge.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        We reserve the right to suspend or terminate Your Account with immediate effect and for an indefinite period, if We have a reason to believe that the Registration Data or any other data provided by You is incorrect or false, or that the security of Your Account has been compromised in any way, or for any other reason We may find just or equitable.
                    </p>
                    <p className="text-white font-poppins mb-5">
                        Except for the Registration Data or any other data submitted by You during the use of any other service offered through the Site (“Permitted Information”), Trifit does not want You to, and You should not send any confidential or proprietary information to Trifit on the Site or otherwise unless otherwise is required by Applicable Laws. In accepting these User Terms You agree that any information or materials that You or individuals acting on Your behalf provide to Trifit other than the Permitted Information will not be considered confidential or proprietary.
                    </p>
                </div>
                <div className="px-5 pt-5">
                    <p className="text-lg text-white font-poppins mb-5">
                        It is Your responsibility to check to ensure that You download the
                        correct application for Your device. We are not liable if You do not
                        have a compatible mobile device or if You download the wrong version
                        of the Application for Your mobile device. We may terminate the
                        Service and the use of the Application if it is used with an
                        unauthorized or incompatible device.
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        We allow you to create only one account using the provided
                        registration data. If you suspect any unauthorized use, please contact
                        us immediately at -----(email Id of Trifit)-----. In case, You are
                        unable to access Your Account, please inform Us at -----(email Id of
                        Trifit)----- and make a written request to block Your Account. We will
                        not be liable for any unauthorized transactions made through Your
                        Account prior to the expiry of 72 (seventy-two) hours after You have
                        made a request in writing for blocking Your Account, and shall not
                        have any liability in case of Force Majeure Events.
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        By using the Application or the Service, You further agree that:
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        (i) You will only use the Service or download the Application for Your
                        sole, personal use and will not resell or assign it to a third party;
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        (ii) You will not use an account that is subject to any rights of a
                        person other than You without appropriate authorization;
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        (iii) You will not use the Service or Site for unlawful purposes;
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        (iv) You will not try to harm the Service, Site or our network in any
                        way whatsoever;
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        (v) You will provide Trifit with such information and documents which
                        Trifit may reasonably request;
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        (vi) You will comply with all Applicable Indian Laws, and to the laws
                        applicable in the State/ City in which You are present while using the
                        Site or Service; and
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        (vii) You are aware of and shall comply with the Information
                        Technology Act, 2000, applicable IT laws concerning data protection,
                        and the rules, regulations and guidelines notified thereunder.
                    </p>
                </div>

                <div className="px-5 pt-5">
                    <p className="text-lg text-white font-poppins mb-5">
                        Trifit reserves the right to immediately terminate the Service and the
                        use of the Application in the event of non-compliance with any of the
                        above requirements. Further, Trifit will store the information
                        provided by You or record your calls for contacting You for all
                        Service-related matters including obtaining feedback in relation to
                        the Services provided/facilitated through the Site/Application. You
                        shall promptly inform Trifit on any change in the information provided
                        by You.
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        Trifit or any of its authorized representatives shall provide
                        information regarding services, discounts, and promotions provided by
                        Trifit to You by way of an SMS or email to Your registered mobile
                        number/registered email ID. You also have the option to discontinue
                        receiving such information at any point in time. To discontinue
                        receiving such information, You may at any point of time visit the
                        specific link provided in the Site/Application to discontinue the
                        same.
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        TRIFIT WILL BE ENTITLED TO PROCESS AND TRANSFER YOUR INFORMATION AS
                        AND WHEN IT DEEMS FIT AND IT MAY STORE OR TRANSFER YOUR INFORMATION IN
                        A SERVER OUTSIDE INDIA OR THE COUNTRY WHERE YOU ARE LOCATED IN ORDER
                        TO PERFORM TRIFIT’S OBLIGATIONS UNDER THESE USER T&C.
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        You agree to grant Trifit a non-exclusive, worldwide, perpetual,
                        irrevocable, royalty-free, sub-licensable (through multiple tiers)
                        right to exercise the copyright, publicity, database rights or any
                        other rights You have in your information, in any media now known or
                        not currently known, with respect to Your information. YOU AGREE AND
                        PERMIT TRIFIT TO SHARE YOUR INFORMATION AND/OR PERMITTED INFORMATION,
                        WITH THIRD PARTIES.
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        You agree and permit Trifit to share any information provided by You
                        with third parties in order to facilitate the provision of Services
                        including certain value-added/ancillary services offered by such third
                        parties to You and/or to provide Services including certain
                        value-added services to You by Trifit. You hereby expressly consent to
                        receive communications from Trifit / third parties offering
                        Services/value-added services to You through Your registered phone
                        number and/or e-mail id and/or the Site. You agree that You will not
                        hold Trifit responsible for any such communications received from
                        third parties, nor will any such communication amount to spam,
                        unsolicited communication or a violation of Your registration on the
                        national do not call/ do not disturb registry.
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        Further, you agree that Trifit may contact you through telephone,
                        email, SMS, or any other electronic means of communication for the
                        purpose of:
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        ● Obtaining feedback in relation to Site/Application or Trifit’s
                        services, including chatbot; and/or
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        ● Obtaining feedback in relation to any Practitioners listed on the
                        Site/Application; and/or
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        ● Connecting for any further details like diagnostics, medicine
                        delivery requests, product redemptions, consultation management, etc.
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        ● Resolving any complaints, information, or queries by Practitioners
                        regarding your critical content; and
                    </p>
                    <p className="text-lg text-white font-poppins mb-5">
                        ● You agree to provide your fullest cooperation further to such
                        communication by Trifit.
                    </p>
                </div>

                <div className="flex flex-col px-5 pt-5 text-white">
                    <p className="text-base mb-5 font-poppins">
                        Against every Practitioner listed in the Site/Application, you get an
                        option to ‘Book an Appointment’. When you choose this option, you
                        choose to schedule a video/audio/chat session with the practitioner
                        through technological services provided by Trifit, and the records of
                        such calls may be recorded and stored in Trifit’s servers. Such
                        records are dealt with only in accordance with the terms of the
                        Privacy Policy. Trifit shall not be responsible for negligence or
                        breach of confidentiality obligations qua such information by the
                        Practitioner or other service providers engaged in providing Services.
                    </p>

                    <p className="text-base mb-5 font-poppins">
                        All claim payouts/reimbursement, cashless etc. will be as per our
                        policy as applicable to Customers as effective and agreed upon from
                        time to time.
                    </p>

                    <p className="text-base mb-5 font-poppins">
                        The Site/Application is deemed to be in use when it is loaded in a
                        temporary or permanent memory of Your computer/Mobile or any other
                        electronic gadget.
                    </p>

                    <p className="text-base mb-5 font-poppins">
                        All transactions, which cannot be carried out instantaneously, shall
                        be carried out during working hours either on the same day or on the
                        next working day or such further period as may be determined and/or
                        specified by Trifit depending upon the time of logging of the
                        transaction. All transactions that are carried out by and on behalf of
                        the Customer shall be subject to Government notifications, the rules,
                        regulations and guidelines issued by the Insurance Regulatory and
                        Development Authority, the Reserve Bank of India, and those of other
                        regulatory bodies defining rules/regulations governing the offer of
                        any service on the Site/Application.
                    </p>

                    <p className="text-base mb-5 font-poppins">
                        The Practitioner is and shall be duly registered, licensed and
                        qualified to practice medicine/ provide health care, wellness
                        services, as per applicable laws/regulations/guidelines set out by
                        competent authorities as per the applicable jurisdiction and the
                        Practitioner shall not be part of any arrangement which will prohibit
                        him/her from practicing medicine within the territory of India. The
                        Practitioner shall at all times ensure that all the applicable laws
                        that govern the Practitioner shall be followed and utmost care shall
                        be taken in terms of the consultation/ services being rendered via the
                        Site/Application. Trifit shall not be responsible for ensuring the
                        authenticity of the documents submitted by the Practitioners for
                        listing on the Site/Application and shall not be liable in any manner
                        whatsoever in case if forged or incorrect documents are submitted by
                        such Practitioners.
                    </p>

                    <p className="text-base mb-5 font-poppins font-bold">
                        5. APPOINTMENT BOOKING AND INTERACTION WITH PRACTITIONERS
                    </p>

                    <p className="text-base mb-5 font-poppins">
                        While Trifit will try to ensure a confirmed on-time appointment for an
                        End-User who requested an appointment on Site/Application, Trifit does
                        not guarantee that a patient will get a confirmed appointment.
                        Further, Trifit has no liability if such an appointment is confirmed
                        but later cancelled by Practitioners, or the Practitioners are not
                        available as per the given appointment time over text, video, audio,
                        or in-person.
                    </p>

                    <p className="text-base mb-5 font-poppins">
                        You understand and agree that any interactions and associated issues
                        with other Users including but not limited to your health issues and
                        your experiences are strictly between you and the other Users. You
                        shall not hold Trifit responsible for any such interactions and
                        associated issues. For the avoidance of doubt, Trifit is not involved
                        in providing any healthcare or medical advice or diagnostic services
                        and is merely a facilitator of the same through the Site/Application
                        and, hence is not responsible for any outcome between you and the
                        Practitioner you interact with, pursuant to any interactions on the
                        Site/Application. Your decision to engage with a Practitioner or
                        chatbot for receiving medical services from him/her is at your own
                        risk. The results of any search you perform on the Site for
                        Practitioners should not be construed as an endorsement by
                        Site/Application of any such particular Practitioner. Trifit shall not
                        be responsible for any breach of service or service deficiency by any
                        Practitioner/chatbot. We cannot assure nor guarantee the ability or
                        intent of the Practitioner(s) and chatbot (in any manner possible) to
                        fulfill their obligations towards you, although we will take complete
                        measures to ensure your satisfaction with the Practitioner/chatbot.
                        Trifit also advises you to perform your own investigation prior to
                        selecting a Practitioner or start the use of Application/Site/chatbot.
                    </p>
                </div>
                <p className="text-white text-base mb-5 font-poppins">
                    Without prejudice to the generality of the above, Trifit will not be liable for:
                </p>

                <ol className="list-decimal list-inside text-white text-base mb-5 font-poppins">
                    <li>Any wrong medication or treatment quality being given by the Practitioner(s), or any medical negligence on the part of the Practitioner(s);</li>
                    <li>Any type of inconvenience suffered by the User due to a failure on the part of the Practitioner to provide agreed services or to make himself/herself available at the appointed time, inappropriate treatment, or similar difficulties;</li>
                    <li>Cancellation or rescheduling of booked appointment;</li>
                    <li>Any medical eventualities that might occur subsequent to using the services of a Practitioner, whom the User has selected on the basis of the information available on the Site/Application or with whom the User has booked an appointment through the Site/Application.</li>
                    <li>Any error in the medical reports provided by the associated bodies/partners/service providers;</li>
                </ol>

                <p className="text-white text-base mb-5 font-poppins">
                    Further, Trifit shall not be liable, under any event, for any comments or feedback given by any of the Users in relation to the services provided by another User/Agency. The option of Users to give feedback remains at Trifit’s sole discretion and may be modified or withdrawn at its sole discretion. Trifit may moderate such feedback at any time. Trifit shall not be obliged to act in any manner to give effect to the content of Users’ feedback, such as suggestions for delisting of a particular Practitioner from the Application/Site.
                </p>

                <p className="text-white text-base mb-5 font-poppins">
                    Trifit collects, directly or indirectly, and displays on the Site/Application, relevant information regarding the profile and practice of the Practitioners/service providers listed on the Site/Application, such as their specialization, qualification, fees, location, visiting hours, and similar details. Trifit takes reasonable efforts to ensure that such information is updated at frequent intervals. Although Trifit screens and vets the information and photos submitted by the Practitioners, Trifit cannot be held liable for any inaccuracies or incompleteness represented from it, despite such reasonable efforts.
                </p>

                <h3 className="text-white text-xl font-bold mb-2 font-poppins">7. CONSULTING SERVICES</h3>

                <p className="text-white text-base mb-5 font-poppins">
                    1. Any decisions to purchase any policy/product of Trifit will be based solely on Your independent evaluation of the policies/products of Trifit, and Your need for the same. You have the responsibility to determine whether the policies/products offered by Trifit satisfy Your requirements. It is Your responsibility to evaluate the completeness, accuracy or usefulness of any opinion, guidance or other content made available on the Site/Application and You should consult Your own legal counsel, business advisor and tax advisor as to the legal, business, tax and related matters concerning any policies with respect to which guidance is sought or given. We have provided the relevant information in relation to the policies/products on the Site/Application.
                </p>

                <p className="text-white text-base mb-5 font-poppins">
                    In the event You require any further information or assistance, you may contact us at ________________(please mention the email).
                </p>

                <p className="text-white text-base mb-5 font-poppins">
                    2. Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively can be referred as “Information”) that may be available on the Site/Application (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between Trifit and you and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is only provided to assist you with locating appropriate medical care from a qualified practitioner.
                </p>

                <p className="text-white text-base mb-5 font-poppins">
                    3. It is hereby expressly clarified that the Information that you obtain or receive from Trifit, and its employees, contractors, partners, sponsors, advertisers, licensors or otherwise on the Site/Application, including chatbot, is for informational purposes only. We make no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise, or other information provided on the Site/Application. In no event shall Trifit be liable to you or anyone else for any decision made or action taken by you in reliance on such information.
                </p>

                <p className="text-white text-base mb-5 font-poppins">
                    4. The Services are not intended to be a substitute for getting in touch with emergency healthcare, like suicidal tendencies and severe mental ailments. If you are an end user facing a medical emergency (either on your or another person’s behalf), please contact an ambulance service or hospital directly.
                </p>
                <div className="flex-1 bg-[#120426] p-5">
                    <div className="px-5 pt-5">
                        <p className="text-white mb-4 font-poppins">
                            1. Subject to Your compliance with these User Terms, Trifit grants You
                            a limited, revocable, non-exclusive, non-transferable and
                            non-sub-licensable license to download and install a copy of the
                            Application on a single mobile device that You own or control and to
                            run such copy of the Application solely for Your own personal use and
                            to use the Site.
                        </p>
                        <p className="text-white mb-4 font-poppins">
                            2. You shall not (i) license, sublicense, sell, resell, transfer,
                            assign, distribute or otherwise commercially exploit or make available
                            to any third party the Service or Site in any way; (ii) modify or make
                            derivative works based upon the Service or Application; (iii) create
                            Internet “links” to the Service or “frame” or “mirror” any Site on any
                            other server or wireless or Internet-based device; (iv) reverse
                            engineer or access the Site in order to (a) design or build a
                            competitive product or service, (b) design or build a product using
                            similar ideas, features, functions or graphics of the Service or Site,
                            or (c) copy, reproduce, record, or make available to the public any
                            ideas, features, functions or graphics of the Service or Site, or (v)
                            launch an automated program or script, including, but not limited to,
                            web spiders, web crawlers, web robots, web ants, web indexers, bots,
                            viruses or worms, or any program which may make multiple server
                            requests per second, or unduly burdens or hinders the operation and/or
                            performance of the Service or Site.
                        </p>
                        <p className="text-white mb-4 font-poppins">
                            3. You shall not: (i) send spam or otherwise duplicative or
                            unsolicited messages in violation of Applicable Laws; (ii) send or
                            store infringing, obscene, threatening, libelous, or otherwise
                            unlawful or tortious material, including material harmful to children
                            or violative of third party privacy rights; (iii) send or store
                            material containing software viruses, worms, Trojan horses or other
                            harmful computer code, files, scripts, agents or programs; (iv)
                            interfere with or disrupt the integrity or performance of the Site,
                            the Application or Service or the data contained therein; or (v)
                            attempt to gain unauthorized access to the Site, the Application or
                            Service or its related systems or networks.
                        </p>
                        <p className="text-white mb-4 font-poppins">
                            4. Trifit will have the right to investigate and prosecute violations
                            of any of the above to the fullest extent of the law. Trifit may
                            involve and cooperate with law enforcement authorities in prosecuting
                            Users who violate these User Terms. You acknowledge that Trifit has no
                            obligation to monitor Your access to or use of the Site, or posted
                            content, but has the right to do so for the purpose of operating the
                            Site and Service, to ensure Your compliance with these User Terms, or
                            to comply with Applicable Law or the order or requirement of a court,
                            administrative agency or other Governmental body. Trifit reserves the
                            right, at any time and without prior notice, to remove or disable
                            access to any content that Trifit, at its sole discretion, considers
                            to be in violation of these User Terms or otherwise harmful to the
                            Site, the Service or Application.
                        </p>
                        <p className="text-white text-xl font-bold mb-4 font-poppins">
                            9. FORCE MAJEURE
                        </p>
                        <p className="text-white mb-4 font-poppins">
                            We shall not be liable for any failure to perform any obligations
                            under these User Terms if the performance is prevented, hindered, or
                            delayed by a Force Majeure Event and in such case, our obligations
                            under this User Terms shall be suspended for so long as the Force
                            Majeure Event continues.
                        </p>
                        <p className="text-white text-xl font-bold mb-4 font-poppins">
                            10. INDEMNIFICATION
                        </p>
                        <p className="text-white mb-4 font-poppins">
                            By accepting these User Terms and using the Service, You agree that
                            You shall defend, indemnify and hold Trifit, its affiliates, their
                            licensors, and each of its officers, directors, other users,
                            employees, attorneys, Practitioners, third-party service providers,
                            and agents harmless from and against any and all claims, costs,
                            damages, losses, liabilities, and expenses (including attorneys’ fees
                            and costs) arising out of or in connection with: (a) Your violation or
                            breach of any term of these User Terms or any Applicable Law or
                            regulation, whether or not referenced herein; (b) Your violation of
                            any rights of any third party; or (c) Your use or misuse of the
                            Application or Service.
                        </p>
                        <p className="text-white text-xl font-bold mb-4 font-poppins">
                            11. LIMITATION OF LIABILITY
                        </p>
                        {/* Additional content can go here */}
                    </div>

                </div>

                <div className="p-5 bg-[#120426] text-white">
                    <p className="font-poppins mb-3 text-base">
                        1. The information, recommendations, and/or Services provided to You on or through the Site/Application are for general information purposes only and do not constitute advice. Trifit will reasonably keep the Site/Application and its contents correct and up to date but does not guarantee that the contents of the Site are free of errors, defects, malware, and viruses or that the Site is correct, up to date, and accurate.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        2. Trifit shall not be liable for any damages resulting from the use of or inability to use the Site/Application, including damages caused by wrong usage of the Site, errors in the call center number, network issues, malware, viruses, or any incorrectness or incompleteness of the Information or the Application.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        3. In no event shall Trifit be liable for:
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        {'\u2022'} Provision of or failure to provide all or any service by Practitioners or listed third parties to users contacted or managed through the Site/Application;
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        {'\u2022'} Any content posted, transmitted, exchanged, or received by or on behalf of any user or other person on or through the Site/Application;
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        {'\u2022'} Any unauthorized access to or alteration of your transmissions or data; or
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        {'\u2022'} Any other matter relating to the Site/Application or the Service.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        4. If Applicable Law does not permit the exclusion of certain warranties or the limitation or exclusion of liability, the scope and duration of such warranty exclusions and the extent of the liability of Trifit shall be the minimum permitted under Applicable Law.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        5. Under no circumstances, including but not limited to negligence,
                        shall Trifit, its employees and directors, its suppliers and its
                        third-party advisors be liable to You for any direct, indirect,
                        incidental, special or consequential damages or any damages whatsoever
                        including punitive or exemplary (including, but not limited to,
                        damages caused by any virus, personal injury, loss of profits, data or
                        other intangible, business interruption, loss of privacy, or any other
                        pecuniary loss), arising out of or in any way connected with the
                        use/delivery/performance of this Site/Application, with the delay or
                        inability to use this Site/Application or any links or items on the
                        Site/Application, the provision of or failure to provide Services, or
                        for any information, software, products, services and related graphics
                        obtained through this Site/Application, or otherwise arising out of
                        the use of this Site/Application, whether based on contract, tort,
                        strict liability or otherwise, or for cost of procurement of
                        substitute goods and repair & correction services or resulting from
                        the use of this Site/Application or obtained or messages received or
                        transactions entered into through or from the Site/Application or
                        resulting from unauthorized access to or alteration of your
                        transmissions or data, even if we have been advised of the possibility
                        of such damages.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        6. You agree that Trifit shall not be liable for any damages arising
                        from interruption, suspension or termination of the Site/Application,
                        including but not limited to direct, indirect, incidental, special,
                        consequential or exemplary damages, whether such interruption,
                        suspension or termination was justified or not, negligent or
                        intentional, inadvertent or advertent. Applicable law may not allow
                        the limitation or exclusion of liability or incidental. or
                        consequential damages. However, in no event shall our liability to you
                        for all damages, losses and causes of action (whether in contract or
                        tort, including but not limited to, negligence) exceed the amount paid
                        by You, if any, for accessing the Site/Application. Policy/ies are
                        marketed and/or distributed solely by Trifit and are not in any way
                        associated to or being sold, marketed or offered for sale by the
                        Payment Gateway Service provider and the Payment Gateway Service
                        provider shall not be liable for the same.
                    </p>
                </div>
                <div className="p-5 bg-[#120426] text-white">
                    <p className="font-poppins mb-3 text-base">
                        All transactions that are carried out by and on behalf of the Customer shall be subject to Government notifications, the rules, regulations, and guidelines issued by the Insurance Regulatory and Development Authority, the Securities and Exchange Board of India, the Reserve Bank of India, and those of other regulatory bodies defining rules/regulations governing the offer of any service on the Site/Application. Trifit does not guarantee or warranty that there will be uninterrupted access to and/or use of the Payment and Delivery Mechanism. Trifit shall not be liable if any transaction does not fructify or may not be completed or for any failure on the part of Trifit to perform any of its obligations under these Terms & Conditions or those applicable specifically to its services/facilities if performance is prevented, hindered, or delayed by a Force Majeure event (defined below) and in such case, its obligations shall be suspended for so long as the Force Majeure event continues.
                    </p>

                    <h2 className="font-poppins text-xl font-bold mb-3">
                        12. THIRD-PARTY WEBSITES
                    </h2>
                </div>
                <div className="p-5 bg-[#120426] text-white">
                    <p className="font-poppins mb-3 text-base">
                        The Site/Application may be linked to the website of third parties, affiliates, and business partners. However, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Site/Application. Your linking to any other off-site websites is at your own risk.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        Trifit has no control over and is not liable or responsible for the content, accuracy, validity, reliability, or quality of such websites or those made available by/through our Site/Application. Trifit assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect Your equipment or access to Your account, use of, or browsing the Site/Application, or the downloading of any material, data, text, images, video content, or audio content from the Site/Application. If You are dissatisfied with the Site/Application, Your sole remedy is to discontinue using the Site/Application.
                    </p>
                </div>
                <div className="p-5 bg-[#120426] text-white">
                    <p className="font-poppins mb-3 text-base">
                        Trifit shall not be liable, at any time, for any failure of performance, error,
                        omission, interruption, deletion, defect, delay in operation or transmission,
                        computer virus, communications line failure, theft or destruction or unauthorized
                        access to, alteration of, or use of information contained at the Site/Application.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        Trifit reserves the right to display sponsored ads on the Site/Application.
                        Without prejudice to the status of other content, Trifit shall not be liable for
                        the accuracy of the information or the claims made in the Sponsored Listings.
                        Trifit does not encourage the Users to visit the Sponsored Listings page or to
                        avail of any services from them. Trifit will not be liable for the services of
                        the providers of the Sponsored Listings. You represent and warrant that you will
                        use these Services in accordance with applicable law. Any contravention of
                        applicable law as a result of your use of these Services is your sole
                        responsibility, and Trifit accepts no liability for the same.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        13. INTELLECTUAL PROPERTY OWNERSHIP
                    </h3>

                    <p className="font-poppins mb-3 text-base">
                        1. Trifit alone (and its licensors, where applicable) shall own all rights, title,
                        and interest, including all related intellectual property rights, copyrights, in
                        and to (i) the Site, Application, Product, Service, and any suggestions, ideas,
                        enhancement requests, feedback, recommendations or any other offering; (ii) text,
                        graphics, user interfaces, visual interfaces, photographs, trademarks, logos,
                        sounds, music, artwork, and computer code; or (iii) other information provided
                        by You or any other party relating to the Site, Application or the Service.
                        Third-party trademarks may appear on this Site/Application and all rights therein
                        are reserved to the registered owners of those trademarks. For use of any third
                        party’s intellectual property, You need to get permission directly from the owner
                        of the intellectual property for any use.
                    </p>
                </div>
                <div className="p-5 bg-[#120426] text-white">
                    <p className="font-poppins mb-3 text-base">
                        2. These User Terms do not constitute a sale and do not convey to You any
                        rights of ownership in or related to the Site, the Application, or the
                        Service, or any intellectual property rights owned by Trifit. You shall be
                        solely responsible for any violations of any laws and for any infringements
                        of any intellectual property rights caused by the use of the Services or the
                        Site/Application.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        14. TERM AND TERMINATION OF USER TERMS
                    </h3>

                    <p className="font-poppins mb-3 text-base">
                        1. Unless terminated, the Agreement between Trifit and You is perpetual
                        upon downloading the Application and availing the Services through the
                        Site/Application.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        2. You can terminate the Agreement at any time by deleting your account,
                        which will disable your access to the Site/Application. You can close Your
                        Account at any time by following the instructions on the Site/Application.
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        3. Trifit reserves the right to suspend or terminate a User’s access to the
                        Site/Application and the Services with or without notice and to exercise any
                        other remedy available under law, in cases where:
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        i) Such User breaches any terms and conditions of the Agreement;
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        ii) A third party reports the violation of any of its rights as a result of
                        your use of the Services;
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        iii) Trifit is unable to verify or authenticate any information provided to
                        Trifit by a User;
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        iv) Trifit has reasonable grounds for suspecting any illegal, fraudulent or
                        abusive activity on part of such User; or
                    </p>

                    <p className="font-poppins mb-3 text-base">
                        v) Trifit believes in its sole discretion that User’s actions may cause legal
                        liability for such User, other Users or for Trifit or are contrary to the
                        interests of the Site/Application.
                    </p>
                </div>
                <div className="p-5 bg-[#120426] text-white">
                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        14. TERM AND TERMINATION OF USER TERMS
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        4. Once temporarily suspended, indefinitely suspended, or terminated, the User
                        may not continue to use the Site/Application under the same account, a different
                        account, or re-register under a new account. On termination of an account due
                        to the reasons mentioned herein, such User shall no longer have access to data,
                        messages, files, and other material kept on the Site/Application by such User.
                        The User shall ensure that he/she/it has continuous backup of any medical
                        services the User has rendered in order to comply with the User’s
                        record-keeping process and practices.
                    </p>
                    <p className="font-poppins mb-3 text-base">
                        5. Termination of this agreement will not prejudice the accrued rights of
                        either Trifit or You.
                    </p>
                    <p className="font-poppins mb-3 text-base">
                        6. Clauses pertaining to Indemnification, Liability, Intellectual Property
                        Ownership, Term and Termination, Notice and Applicable Law and Dispute
                        Resolution, and such other provisions which are intended to survive the
                        termination, shall survive the expiry/termination of these User Terms in
                        accordance with their terms.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        15. INVALIDITY OF ONE OR MORE PROVISIONS
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        1. The invalidity of any term of these User Terms shall not affect the
                        validity of the other provisions of these User Terms. If and to the extent
                        that any provision of these User Terms is invalid, or is unacceptable in the
                        given circumstances, a provision shall apply between the parties instead that
                        is acceptable considering all the circumstances, taking into account the
                        content and the purpose of these User Terms.
                    </p>
                </div>
                <div className="p-5 bg-[#120426] text-white">
                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        16. CONFLICT
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        In the event of any contradiction or inconsistency between this User Terms
                        and any other agreement executed between You and Trifit, the terms of the
                        User Terms shall prevail unless the exception has been expressly agreed to
                        in writing by making reference to the relevant Clause sought to be modified
                        under this User Terms.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        17. DISCLAIMER
                    </h3>

                    <h4 className="font-poppins mb-3 text-base font-semibold">
                        a) No Warranty
                    </h4>
                    <p className="font-poppins mb-3 text-base">
                        Although all efforts are made to ensure that information and content
                        provided as part of the Site/Application are correct at the time of
                        inclusion on the Site/Application, there is no guarantee to the accuracy of
                        the Information. We do not make representations or warranties as to the
                        fairness, completeness or accuracy of Information on the Site/Application.
                        There is no commitment to update or correct any information that appears
                        on the Internet or on the Site/Application. Information is supplied upon
                        the condition that the persons receiving the same will make their own
                        determination as to its suitability for their purposes prior to use or in
                        connection with the making of any decision. Any use of the Site/Application
                        or the information is at your own risk.
                    </p>

                    <h4 className="font-poppins mb-3 text-base font-semibold">
                        b) No Liability
                    </h4>
                    <p className="font-poppins mb-3 text-base">
                        The Services provided by Trifit or any of its licensors or service
                        providers/partners are provided on an “as is” and “as available” basis,
                        and without any warranties or conditions (express or implied, including the
                        implied warranties of merchantability, accuracy, fitness for a particular
                        purpose, title and non-infringement, arising by statute or otherwise in
                        law or from a course of dealing or usage or trade). Trifit does not
                        provide or make any representation, warranty or guarantee, express or
                        implied about the Site/Application or the Services. Trifit does not
                        guarantee the accuracy or completeness of any content or information
                        provided by users on the Site/Application. To the fullest extent permitted
                        by law, Trifit disclaims all liability arising out of Your use or reliance
                        upon the Site/Application, the Services, representations, and warranties
                        made by other users, or any content or information provided by the users
                        on the Site/Application.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        18. AUTHORITY TO TRIFIT
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        The Customer/You hereby authorize Trifit to carry out his instructions,
                        effecting such transactions as may be permitted by Trifit from time to
                        time, in accordance with these Terms & Conditions and such other terms as
                        may be specified by.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        19. EVIDENCE OF TRANSACTIONS
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        For any transaction which may be permitted by Trifit from time to time,
                        Trifit’s own records of such transactions maintained through computer
                        systems or otherwise, shall be accepted as conclusive and binding for all
                        purposes. The record of the Transaction as generated from the systems of
                        Trifit shall be conclusive proof of the genuineness and accuracy of such
                        transactions.
                    </p>
                </div>
                <div className="p-5 bg-[#120426] text-white">
                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        20. RESTRICTIONS ON USE
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        Trifit owns and holds all the rights for the information, contents,
                        audio, video, logos and trademarks contained in this Site/Application.
                        Any reproduction, modification, creation of derivative works,
                        distribution, transmission, copying, selling, displaying, publishing
                        or using any of the information, contents, audio, video, logos and
                        trademarks contained in this Site/Application for any purpose
                        whatsoever, whether electronically or otherwise, without the prior
                        written permission of Trifit is strictly prohibited. Any violation of
                        this provision would be strictly dealt with. You may download material
                        displayed on this Site/Application for your personal use only,
                        provided that you also retain the clauses pertaining to all copyright
                        and other proprietary notices contained in the materials.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        21. USER OBLIGATIONS
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        You acknowledge and affirm that You shall not:
                    </p>

                    <h4 className="font-poppins mb-3 text-base font-semibold">
                        a) Fraudulent, Abusive, or Illegal Activity
                    </h4>
                    <p className="font-poppins mb-3 text-base">
                        Engage in fraudulent, abusive, or illegal activity, including but not
                        limited to any communication or solicitation designed or intended to
                        fraudulently obtain the password or any private information of any
                        user.
                    </p>

                    <h4 className="font-poppins mb-3 text-base font-semibold">
                        b) Violation of Security
                    </h4>
                    <p className="font-poppins mb-3 text-base">
                        Use the Site/Application to violate the security of any computer
                        network, crack passwords or security encryption codes, transfer or
                        store illegal material including threatening or obscene material, or
                        engage in any kind of illegal activity.
                    </p>

                    <h4 className="font-poppins mb-3 text-base font-semibold">
                        c) Monitoring and Responsibility
                    </h4>
                    <p className="font-poppins mb-3 text-base">
                        Although Trifit may, from time to time, monitor or review any
                        facilities, if established or otherwise offered at the
                        Site/Application for discussions, chats, postings, transmissions,
                        bulletin boards, and the like on the Site/Application, Trifit is under
                        no obligation to do so and assumes no responsibility or liability
                        arising from the content of any such locations nor for any error,
                        defamation, libel, slander, omission, falsehood, obscenity,
                        pornography, profanity, danger, or inaccuracy contained in any
                        information contained within such locations on the Site/Application.
                        You are prohibited from posting or transmitting any unlawful,
                        threatening, libelous, defamatory, obscene, scandalous, inflammatory,
                        pornographic, or profane material or any material that could
                        constitute or encourage conduct that would be considered a criminal
                        offense, give rise to civil liability, or otherwise violate any law.
                        Trifit will fully cooperate with any law enforcement authorities or
                        court order requesting or directing Trifit to disclose the identity of
                        anyone posting any such information or materials.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        21. USE OF INFORMATION
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        The content is available for informational purposes only. The posting
                        of contents and access to this Site/Application does not render,
                        either explicitly or implicitly, any provision of Services or products
                        by us. All advertisements contain only an indication of the cover
                        offered by the products. For more information, please read the policy
                        wording before concluding a sale.
                    </p>
                </div>
                <div className="p-5 bg-[#120426] text-white">
                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        22. RESERVATION OF RIGHTS
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        We reserve the right to change, modify, add to, or remove discounts,
                        and portions of these terms of use at any time, subject to all/ any
                        applicable regulations as amended from time to time & Trifit’s policy.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        23. PRIVACY POLICY
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        In the process of using this Site/Application, the Privacy Policy as
                        outlined in the Site/Application ------------(website url)------------
                        shall be applicable.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        24. MODIFICATION OF THE SERVICE AND USER TERMS
                    </h3>

                    <h4 className="font-poppins mb-3 text-base font-semibold">
                        1. Modification or Replacement of User Terms
                    </h4>
                    <p className="font-poppins mb-3 text-base">
                        Trifit reserves the right, at its sole discretion, to modify or
                        replace, in part or full, any of these User Terms, or change, suspend,
                        block, discontinue or restrict Your use to all or any feature of the
                        Service or Site/Application at any time.
                    </p>

                    <h4 className="font-poppins mb-3 text-base font-semibold">
                        2. Notification of Changes
                    </h4>
                    <p className="font-poppins mb-3 text-base">
                        Trifit shall not be required to notify You of any changes made to
                        these User Terms. The revised User Terms shall be made available on
                        the Site. You are requested to regularly visit the Site to view the
                        most current User Terms. It shall be Your responsibility to check
                        these User Terms periodically for changes. Your continued use of the
                        Site/Application constitutes acceptance of the changes and an
                        Agreement to be bound by User Term, as amended.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        25. NOTICE
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        Notices under these Terms & Conditions may be given by Trifit to the
                        Customer in writing by flashing them on the Application/website.
                        Customer may give notice to Trifit by delivering them to the address
                        mentioned on Site/Application. Documents, which may be sent by
                        electronic communication between the parties, may be in the form of an
                        electronic mail, an electronic mail attachment, or in the form of an
                        available download from the Site/Application. Trifit shall be deemed
                        to have fulfilled any legal obligation to deliver to the Customer if
                        any such document is sent via electronic delivery.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        26. EXCLUSIVE AGREEMENT
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        You agree that these User Terms are the complete and exclusive
                        statement of agreement that supersedes any proposal or prior
                        agreement, oral or written, and any other communications between you
                        and Trifit relating to the subject matter of these User Terms. These
                        User Terms, as the same may be amended from time to time, will prevail
                        over any subsequent oral communications between you and the
                        Site/Application and/or Trifit.
                    </p>

                    <h3 className="font-poppins mb-3 text-lg font-bold">
                        27. ASSIGNMENT
                    </h3>
                    <p className="font-poppins mb-3 text-base">
                        You shall not assign Your rights under these User Terms without prior
                        written approval of Trifit. Trifit can assign its rights under the
                        User Terms to any affiliate.
                    </p>
                </div>
                <div className="font-poppins text-white p-4">
                    <h2 className="text-lg font-semibold mb-2">28. NO WAIVER</h2>
                    <p className="text-base mb-4">
                        Trifit or any of its third-party service providers etc. shall not be deemed to have waived any of its/their rights or remedies hereunder unless such waiver is in writing. No delay or omission on the part of Trifit or service providers, in exercising any rights or remedies shall operate as a waiver of such rights or remedies or any other rights or remedies. A waiver on any one occasion shall not be construed as a bar or waiver of any rights or remedies on future occasions.
                    </p>

                    <h2 className="text-lg font-semibold mb-2">29. APPLICABLE LAW AND DISPUTE RESOLUTION</h2>
                    <p className="text-base mb-4">
                        These User Terms are subject to the laws of India. Any dispute, claim, or controversy arising out of or relating to these User Terms or the breach, termination, enforcement, interpretation, or validity thereof or the use of the Site, the Service, or the Application (collectively referred to as “Disputes”) the parties shall attempt to settle the same amicably, through negotiation and consultation at such offices of Trifit as Trifit may designate.
                        <br />
                        In the event the dispute is not resolved internally after at least 30 (thirty) days of negotiation, in good faith, the same shall be submitted for Arbitration in accordance with the Arbitration and Conciliation Act, 1996 as amended from time to time or in case the Arbitration and Conciliation Act, 1996 is no longer in force, as per any law relating to arbitration in force in India at the time of such reference. The dispute shall be referred to a sole arbitrator appointed by Trifit. The seat of the arbitration shall be Bengaluru, Karnataka unless otherwise mutually agreed by Trifit and You in writing. Subject to the above, any Dispute will be subject to the exclusive jurisdiction of courts in Bangalore, India.
                    </p>

                    <h2 className="text-lg font-semibold mb-2">30. JURISDICTIONAL ISSUES</h2>
                    <p className="text-base mb-4">
                        1. Unless otherwise expressly set forth herein, Trifit makes no representation that materials on this Site/Application are appropriate or available for use in any location. Those who choose to access this Site/Application do so on their own initiative and are responsible for compliance with local laws. The Services/products that are part of the Site/Application do not constitute an offer to sell or a solicitation of an offer to buy any policies/products to any person in any jurisdiction where it is unlawful to make such an offer or solicitation.
                    </p>
                    <p className="text-base mb-4">
                        2. Except as specifically set forth herein, the information contained on this Site/Application is not an offer to sell any health, wellness product, or other product or service. Some products and Services may not be available.
                    </p>

                    <h2 className="text-lg font-semibold mb-2">31. CONTACT</h2>
                    <p className="text-base mb-4">
                        You authorize Trifit to call, send SMS, and email on the contact number and email ID (made available by you on our Site/Application) and offer you information and services for the product/Services you have opted for. You authorize us to call, SMS or email You for the mentioned purpose, irrespective of whether you register with the NDNC registry. Trifit may from time to time send by e-mail or otherwise, information relating to products and services offered by its Affiliates, general information related to financial and other services, and life and general insurance products. For the sake of clarification, the abovementioned right of Trifit shall continue to be in effect irrespective of Your termination of the Agreement/deletion of Your Account.
                    </p>

                    <h2 className="text-lg font-semibold mb-2">32. GRIEVANCES</h2>
                    <p className="text-base mb-4">
                        For grievances, please write to us:
                        <br />
                        M/s Svar Trifit Wellness Private Limited
                        <br />
                        Registered Office: New No 59, Old No 120, Residency Road, Richmond Town, Bangalore, Bangalore North, Karnataka, India, 560025.
                        <br />
                        <p className="text-start w-full  font-bold underline font-poppins">
                            Email ID: 
                            <a href="mailto:contact@trifitindia.com" className="text-blue-800">
                                contact@trifitindia.com
                            </a>
                        </p>
                        TERMS & CONDITIONS GOVERNING THE USE OF TRIFIT’S WEBSITE BY VISITORS, CUSTOMERS AND REGISTERED USERS.
                    </p>
                </div>
            </div>
        </div>

    )
}

export default PrivacyPolicyAndCondition