import 'animate.css';

import React from 'react';
import { IoMdArrowBack } from "react-icons/io";
import { IoArrowForward } from "react-icons/io5";

import { Link, useNavigate } from 'react-router-dom';
import first from "../../Images/first.jpeg";




const Welcomepagefirst = () => {
    const navigate = useNavigate()


    return (
        <div className=" bg-[#120426]  flex items-center justify-center pb-12 ">


            <div className=" p-8 rounded-lg  xs:pb-20  w-[90%] xs:min-h-screen     xs:w-[100%]  sm:w-[95%]  m-auto">
                <div className="flex  xs:flex-col-reverse  md:flex-row justify-beween items-center">


                    <div className="  text-center  text-white mb-8 md:mb-0">
                        <h1 className="lg:text-5xl sm:text-4xl  xs:text-xl    xsm:text-3xl  tracking-wide   w-4/5  xs:w-[100%] sm:w-4/5  sm:p-6  m-auto sm:mt-4  font-semibold mb-6 font-poppins animate__animated animate__fadeIn  ">
                            Welcome to the World of Trifit
                        </h1 >
                        <div className=" space-y-4">

                            <div>
                         
                                <button onClick={() => navigate("/welcomesecond")} className="bg-[#5D1BBC] flex justify-center group relative  font-poppins m-auto items-center text-white font-semibold py-3 lg:text-2xl md:text-xl sm:text-lg px-14 rounded-full border-4 border-white   hover:border-[#5D1BBC]   overflow-hidden">

                                    <div className="absolute left-0 h-full   w-0 py-3 rounded-full bg-white transition-all duration-1000 ease-in-out group-hover:w-full "></div>

                                    <span className="relative  transition-colors duration-1000 group-hover:text-[#5D1BBC]">
                                        Continue
                                    </span>
                                    <IoArrowForward className='inline lg:text-4xl relative  ml-2 md:text-2xl sm:text-xl xs:text-lg transition-colors duration-1000 group-hover:text-[#5D1BBC]' />
                                </button>
                             
                            </div>


                            <div className='w-fit m-auto mt-4'>
                                <Link to="/">
                                    <button className="bg-[#5D1BBC] flex justify-center group relative font-poppins items-center text-white font-semibold py-3 lg:text-2xl md:text-xl sm:text-lg px-14 rounded-full border-4 border-white hover:border-[#5D1BBC]  overflow-hidden">
                                        <div className="absolute h-full right-0  w-0 py-3 rounded-full bg-white transition-all duration-1000 ease-in-out group-hover:w-full  "></div>
                                        <IoMdArrowBack className='inline lg:text-4xl relative  mr-2 md:text-2xl sm:text-xl xs:text-lg transition-colors duration-1000 group-hover:text-[#5D1BBC]' />
                                        <span className="relative  transition-colors duration-1000 group-hover:text-[#5D1BBC]">
                                            Back
                                        </span>
                                    </button>
                                </Link>
                            </div>

                        </div>
                    </div>


                    <div className="lg:w-[500px]  lg:h-[500px] md:w-[400px] h-[400px] sm:w-[300px]      sm:h-[300px] xs:w-[250px] xs:h-[250px] w-full    mb-16   m-auto">
                        <img
                            src={first}
                            alt="welcome"
                            className="w-[100%] xs:rounded-full h-[100%] m-auto mt-8   sm:rounded-none"
                        />
                    </div>

                </div>

            </div>

        </div>
    );
};





export default Welcomepagefirst