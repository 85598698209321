import React from 'react'
import belt from "../../Assests/Images/belt.svg"
import graph from "../../Assests/Images/graph.svg"
import homesvg from "../../Assests/Images/homesvg.svg"
import music from "../../Assests/Images/music.svg"
import scanner from "../../Assests/Images/scanner.svg"

const Trisipcard = () => {
    return (
        <div className=' bg-[#160F25] '>
            <div className='w-[90%] m-auto'>
                <div>
                    <h1 className="lg:text-4xl font-bold dmd:text-3xl  sm:text-2xl  xs:text-lg   text-[#D94496] md:mb-0 xs:mb-4  xsm:text-lg  tracking-wide   w-4/5  xs:w-[100%] sm:w-4/5  sm:p-6  m-auto text-center font-poppins md:py-4 xs:py-6 ">The Trifit Formula</h1>
                    <div className=' grid  lg:grid-cols-5 py-2  xsm:grid-cols-2  xs:grid-cols-1'>
                        <div className=' xs:mb-4 xs:mx-4  rounded-lg '>
                            <div className='  w-fit m-auto'>
                                <img className=' lg:w-[260px]  md:w-[200px] xs:w-[150px]      min-h-[320px]   ' src={scanner} alt="scanner" />
                            </div>
                            <div className='text-'>
                                <h4 className='font-poppins my-2  lg:text-lg  text-[rgba(217,69,150,1)] sm:text-lg  xs:text-base text-center'>Systemic Tracking</h4>
                                <p className=' w-4/5 m-auto text-center text-[#F5F5F5]    xs:text-sm font-poppins'>Adaptable System allowing for broad integration of activities!</p>
                            </div>
                        </div>
                        <div className=' xs:mb-4 xs:mx-4  rounded-lg  '>
                            <div className=' w-fit m-auto'>
                                <img className=' lg:w-[260px]  md:w-[200px] xs:w-[150px]       min-h-[320px]     ' src={homesvg} alt="homesvg" />
                            </div>
                            <div className='text- text-[#F5F5F5]   '>
                                <h4 className='font-poppins  my-2  lg:text-lg  text-[rgba(217,69,150,1)]  sm:text-lg  xs:text-base text-center'>Gamification</h4>
                                <p className=' w-4/5 m-auto text-center xs:text-sm font-poppins'> Transforming wellness struggles into motivating victory-driven battles!</p>
                            </div>



                        </div>
                        <div className='  xs:mb-4 xs:mx-4  rounded-lg  '>
                            <div className=' w-fit m-auto'>
                                <img className=' lg:w-[260px]  md:w-[200px] xs:w-[150px]           min-h-[320px]     ' src={music} alt="music" />
                            </div>
                            <div className='text- text-[#F5F5F5]  '>
                                <h4 className='font-poppins  my-2  lg:text-lg  text-[rgba(217,69,150,1)]  sm:text-lg  xs:text-base text-center'>Empathetic Support</h4>
                                <p className=' w-4/5 m-auto text-center   xs:text-sm font-poppins'>Anthropomorphic support lifting you up and celebrating your successes!</p>
                            </div>



                        </div>
                        <div className='  xs:mb-4 xs:mx-4  rounded-lg  '>
                            <div className=' w-fit m-auto'>
                                <img className=' lg:w-[260px]  md:w-[200px] xs:w-[150px]           min-h-[320px]     ' src={graph} alt="graph" />
                            </div>
                            <div className='text- text-[#F5F5F5]  '>
                                <h4 className='font-poppins  my-2  lg:text-lg text-[rgba(217,69,150,1)]   sm:text-lg  xs:text-base text-center'>Holistic Plans</h4>
                                <p className=' w-4/5 m-auto text-center      xs:text-sm font-poppins'>Holistic Approach allowing for continuous, sustainable, and amalgamated growth! </p>
                            </div>



                        </div>
                        <div className='  xs:mb-4 xs:mx-4  rounded-lg  '>
                            <div className=' w-fit m-auto'>

                                <img className=' lg:w-[260px]  md:w-[200px] xs:w-[150px]       min-h-[320px]    ' src={belt} alt="belt" />
                            </div>
                            <div className=' text-#F5F5F5  '>
                                <h4 className='font-poppins   my-2 lg:text-lg  text-[rgba(217,69,150,1)] sm:text-lg  xs:text-base text-center'>Social Support</h4>
                                <p className=' w-4/5 m-auto text-center text-[#F5F5F5]   xs:text-sm  font-poppins'>Our social features enable social support, without the negative effects of mainstream social media! </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trisipcard