import { UserButton, useUser } from '@clerk/clerk-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import trifitlogo from "../Images/Trifit.svg";
import HamburgerMenu from './HamburgerMenu';
import Sidebar from './Sidebar';


const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { isSignedIn } = useUser();
    let isloggedoutvalue = localStorage.getItem("isloggedout")
    console.log(isloggedoutvalue, "value of logout")

    const navigate = useNavigate()

    const notify5 = () => {
        toast.success('Logout successfull', {
            style: {
                border: "2px solid  #120426 ",
                fontWeight: "700"

            }

        })

    }

    useEffect(() => {

        if (isSignedIn == false) {
            if (isloggedoutvalue === "false") {
                notify5()

                localStorage.removeItem("isloggedout")
            }
        }


    }, [isSignedIn])


    const handleDownload = () => {
        window.location.href = "https://trifit11.s3.ap-south-1.amazonaws.com/Trisip.apk";
    }


    const handleNavigation = (path) => {
        navigate(path);
        setIsOpen(false);
    };


    return (
        <>
            <nav className="bg-[#D94496]  font-poppins z-50  sticky top-0  shadow-lg py-2.5">
                <div className="container max-w-full lg:mx-auto lg:px-16 px-8 flex justify-between items-center h-16">
                    <div className='pt-6 pb-6 flex justify-center items-center'>
                        <img className='bg-white w-16 rounded-full' src={trifitlogo} alt="Trifit" />
                        <p className='text-white text-3xl ml-2 font-semibold font-poppins '>TriFit</p>
                    </div>
                    <ul className="space-x-10 text-lg hidden lg:flex mr-12 font-poppins font-semibold">


                        {
                            isSignedIn ? <UserButton className="z-50 bg-red" /> : null
                        }


                        {/* <Link to="/">
                            <li className="text-white ">Home</li>
                        </Link> */}
                        <ScrollLink to="home" smooth={true} duration={800}>

                            <li onClick={() => handleNavigation('/')} className="text-white cursor-pointer  "> Home</li>
                        </ScrollLink>
                        <ScrollLink to="blogs" smooth={true} duration={800}>


                            <li className="text-white  cursor-pointer "> Blogs</li>
                        </ScrollLink>

                        <Link to="/">
                            <li className="text-white cursor-pointer">About Us</li>
                        </Link>
                        {/* <Link to="/contact">
                            <li className="text-white">Contact Us</li>
                        </Link> */}
                        <ScrollLink to="contact" smooth={true} duration={800}>
                            <li className="text-white cursor-pointer">Contact Us</li>
                        </ScrollLink>


                        <li onClick={handleDownload} className="text-white  cursor-pointer ">Download App</li>
                        <Link to="/">
                            <li className="text-white">Sign Up</li>
                        </Link>
                    </ul>
                    <div className="lg:hidden">
                        <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen} />
                    </div>
                </div>
            </nav>


            <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />



        </>
    );
};

export default Navbar;
