import React from 'react';

const HamburgerMenu = ({setIsOpen, isOpen}) => {



  const genericHamburgerLine = `sm:h-1  sm:w-6  sm:my-1 xs:h-1 xs:w-6  xs:my-1  rounded-full bg-white transition ease-in-out transform duration-500`;

  return (
    <button
      className="flex flex-col sm:h-12 sm:w-12   xs:h-10 xs:w-10  border-2 border-white rounded justify-center items-center group"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        className={`${genericHamburgerLine} ${
          isOpen ? 'rotate-45 translate-y-3' : 'opacity-90 group-hover:opacity-100'
        }`}
      />
      <div
        className={`${genericHamburgerLine} ${isOpen ? 'opacity-0' : 'opacity-90 group-hover:opacity-100'}`}
      />
      <div
        className={`${genericHamburgerLine} ${
          isOpen ? '-rotate-45 -translate-y-3' : 'opacity-90 group-hover:opacity-100'
        }`}
      />
    </button>
  );
};

export default HamburgerMenu;
