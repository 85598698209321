
import { UserButton, useUser } from '@clerk/clerk-react';
import React from 'react';
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const Sidebar = ({ setIsOpen, isOpen }) => {
    const { isSignedIn } = useUser();
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleDownload = () => {
        window.location.href = "https://trifit11.s3.ap-south-1.amazonaws.com/Trisip.apk";
        // setIsOpen(false);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsOpen(false);
    };

    return (
        <div
            className={`fixed top-0 left-0 h-full z-50 xs:w-full xs:bg-[#D94496] sm:bg-[#D94496] xs:text-[#F5F5F5] sm:text-black xs:text-center xsm:w-3/5 sm:w-1/2 md:w-3/5 shadow-2x text-black z-100 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-700 ease-in-out overflow-y-auto`}
        >
            <div className="p-6">
                <button
                    className="text-black sm:w-fit xs:w-full xs:flex xs:justify-end lg:text-3xl sm:text-black sm:text-xl xs:text-lg xsm:text-xl rounded-lg p-2 xs:text-[#F5F5F5] focus:outline-none"
                    onClick={toggleSidebar}
                >
                    <RxCross1 />
                </button>
                <ul className="mt-8 sm:text-start lg:text-3xl sm:text-2xl xs:text-lg xsm:text-xl font-poppins space-y-4">
                    {isSignedIn ? (
                        <ul>
                            <UserButton className="z-50" />
                        </ul>
                    ) : null}
                    <ScrollLink to="home" smooth={true} duration={800}>
                        <li onClick={() => handleNavigation('/')} className=" cursor-pointer mt-4 "> Home</li>
                    </ScrollLink>
                    <ScrollLink to="blogs" smooth={true} duration={800}>
                        <li onClick={() => handleNavigation('/')} className="mt-4 cursor-pointer">Blogs</li>
                    </ScrollLink>
                    <li className='cursor-pointer' onClick={() => handleNavigation('/')}>About Us</li>
                    {/* <li>Contact Us</li> */}
                    <ScrollLink to="contact" smooth={true} duration={800}>
                        <li onClick={() => handleNavigation('/')} className=" cursor-pointer mt-4 ">Contact Us</li>
                    </ScrollLink>
                    <li onClick={handleDownload} className="text-white cursor-pointer">Download App</li>
                    <li onClick={() => handleNavigation('/')} className="t cursor-pointer ">Sign Up</li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
