import { Toaster } from "react-hot-toast";
import Navbar from "./Components/Navbar";
import Allroutes from "./Routes/Allroutes";



export default function App() {
  return (
    <div>
      <Navbar />
      <Allroutes />
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </div>
  )
}