import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import forge from "../../Assests/Images/forge.svg";
import theopuzzle from "../../Assests/Images/theopuzzle.gif";
import theosliderboxer from "../../Assests/Images/theosliderboxer.gif";

const Slider = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const fullText_first = `TriFit promotes stronger connections between users and their fitness goals through gamification and hydration tracking. With a user-friendly interface and seamless QR scanning, users can track daily water intake and earn rewards, keeping them engaged and motivated. The app provides personalized notifications and reminders to ensure adherence, making it easier to stay on track with daily hydration goals.`;

    const fullText_second = `TriFit goes beyond hydration and fitness by incorporating holistic features like mental well-being support and empathy-based feedback. Users can set mental health goals, track their progress, and receive supportive messages to keep them motivated. This approach ensures a well-rounded health experience, focusing on physical and mental wellness for sustainable self-improvement.`;

    const fullText_third = ` TriFit fosters a community-driven approach to fitness, integrating challenges. The app allows users to compete and collaborate by setting common goals, creating a sense of camaraderie and shared success. Group features like empathy-driven notifications and rewards make fitness a fun, collective experience, boosting long-term engagement and motivation.`;


    
    


    const truncatedText_first = "Participate in challenges and leaderboards to stay engaged. Compete and collaborate with others to hit your fitness goals.";
     const truncatedText_second = "TriFit supports both your physical and mental health through empathy-driven feedback and holistic tracking for a balanced lifestyle.";
     const truncatedText_third = "Track your hydration with easy QR code scanning and stay motivated with rewards and daily reminders to reach your goals."


    useEffect(() => {
        const checkScreenSize = () => {
            if (window.innerWidth < 768) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };

        checkScreenSize(); // Initial check
        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);



    return (
        <div className='bg-[#160F25] pt-2 md:pt-8 w-full'>
            <div className='text-white   pb-4 sm:pb-0 '>
                <h1 className="lg:text-4xl font-bold dmd:text-3xl  sm:text-2xl  xs:text-lg   text-[#D94496] md:mb-0 xs:mb-4  xsm:text-lg tracking-wide  w-4/5  md:w-3/5   xs:w-[90%] sm:w-4/5  sm:p-6  m-auto sm:mt-4  text-center font-poppins   "> Why Our Approach to Wellness is Unlike Any Other</h1>
                {/* <p className="lg:text-lg sm:text-lg  xs:text-xs    xsm:text-base  text-center tracking-wide   w-4/5  xsm:w-[80%]  lg:w-[70%]    m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins animate__animated animate__fadeIn  "  >In a distant land, hidden from the eyes of humans, four powerful clans ruled the wilderness. But now, you have the chance to become  the protagonist of your own transformative journey. Welcome to Trifit, where you’ll assume
                    the role of a hero embarking on a quest for holistic self-improvement.</p> */}
            </div>
            <div >
                <Carousel showIndicators={false} swipeable={false} emulateTouch={true} showArrows={false} showStatus={false} showThumbs={false} autoPlay={true} interval={1500} infiniteLoop={true}   >
                    {
                        isSmallScreen ?


                            <div style={{ userSelect: 'none' }} className='flex md:w-[80%] xs:h-[320px] sm:h-[400px]  xsm:w-[70%]  xs:w-4/5  flex-col md:flex-row  bg-[#413651]   m-auto justify-center cursor-grab   items-center rounded-3xl xs:p-4 p-10 '>
                                <div className=" text-black   xs:w-full  md:w-4/5 ">
                                <h1 className=" sm:text-xl  xs:text-lg    xsm:text-lg    md:text-start text-center font-bold  text-[#D94496]    sm:p-2  m-auto sm:mt-4  font-poppins "  >Group Motivation</h1>
                                    <div className='  lg:w-[310px] lg:h-[310px]  md:w-[270px] md:h-[270px]    m-auto sm:w-[230px] sm:h-[230px]  xs:w-[180px] xs:h-[180px]  xsm:w-[200px] xsm:h-[200px] '>
                                        {/* <img className='   ' src="https://trifitindia.com/wp-content/uploads/2024/04/boxing.gif" alt="Carousel 1" /> */}
                                        <img src={theosliderboxer} alt="" />
                                    </div>

                                    <p className="lg:text-lg sm:text-base   xs:text-xs  text-[#f5f5f5]    xsm:text-xs  text-center      m-auto  lg:mt-0  font-poppins  "  >

                                        {truncatedText_first}
                                        .</p>
                                </div>




                            </div>


                            :


                            <div style={{ userSelect: 'none' }} className='flex md:w-[80%]   xsm:w-3/5  xs:w-4/5  flex-col md:flex-row   bg-[#413651]  m-auto justify-center cursor-grab   items-center rounded-3xl xs:p-4 p-10 '>
                                <div className=" text-black    xs:w-full  md:w-3/5 ">
                                    <h1 className=" sm:text-xl  xs:text-lg    xsm:text-lg       text-[#D94496]  font-bold    sm:p-2  m-auto sm:mt-4  text-start font-poppins   relative  lg:right-[12px]  w-full "  >Group Motivation</h1>
                                    <p className="  xs:text-xs    xsm:text-base  text-start  text-[#f5f5f5]      m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins  "  >

                                        {fullText_third}
                                        .</p>
                                </div>

                                <div className='  lg:w-[310px] lg:h-[310px]  md:w-[270px] md:h-[270px]   sm:w-[230px] sm:h-[230px]  xs:w-[180px] xs:h-[180px]  xsm:w-[200px]  '>
                                    {/* <img className='    ' src="https://trifitindia.com/wp-content/uploads/2024/04/boxing.gif" alt="Carousel 1" /> */}
                                    <img src={theosliderboxer} alt="" />
                                </div>


                            </div>
                    }

                    {
                        isSmallScreen ?

                            <div style={{ userSelect: 'none' }} className='flex md:w-[80%] xs:h-[320px]  sm:h-[400px]  xsm:w-[70%] xs:w-4/5  flex-col md:flex-row   bg-[#413651]   m-auto justify-center cursor-grab   items-center rounded-3xl xs:p-4 p-10 '>
                                <div className=" text-black   xs:w-full  md:w-4/5 ">
                                    <h1 className=" sm:text-xl  xs:text-lg    xsm:text-lg   text-[#D94496] font-bold   sm:p-2  m-auto sm:mt-4   md:text-start text-center font-poppins "  >Your Mental Well Being Ally </h1>

                                    <div className='  lg:w-[310px] lg:h-[310px]  md:w-[270px] md:h-[270px]  m-auto  sm:w-[230px] sm:h-[230px]  xs:w-[180px] xs:h-[180px]  xsm:w-[200px] '>
                                        {/* <img className='    ' src="https://trifitindia.com/wp-content/uploads/2024/04/puzzle.gif" alt="Carousel 2" /> */}
                                        <img src={theopuzzle} alt="" />

                                    </div>
                                    <p className="lg:text-lg sm:text-base  text-[#f5f5f5]   xs:text-xs    xsm:text-xs  text-center      m-auto xsm:mt-2 lg:mt-0 font-poppins    "  > {truncatedText_second}.</p>
                                </div>


                            </div>
                            :
                            <div style={{ userSelect: 'none' }} className='flex md:w-[80%]   xsm:w-3/5  xs:w-4/5  flex-col md:flex-row  bg-[#413651]   m-auto justify-center cursor-grab   items-center rounded-3xl xs:p-4 p-10 '>
                                <div className=" text-black  xs:w-full  md:w-3/5 ">
                                <h1 className=" sm:text-xl  xs:text-lg    xsm:text-lg   text-[#D94496]  relative   lg:right-[12px]   font-bold   sm:p-2  m-auto sm:mt-4  text-start font-poppins "  >Your Mental Well Being Ally </h1>
                                    <p className="  xs:text-xs    xsm:text-base  text-start   text-[#f5f5f5]     m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins  "  >   {fullText_second} </p>
                                </div>

                                <div className='  lg:w-[310px] lg:h-[310px]  md:w-[270px] md:h-[270px]   sm:w-[230px] sm:h-[230px]  xs:w-[180px] xs:h-[180px]  xsm:w-[200px] xsm:h-[200px] '>
                                    {/* <img className='   ' src="https://trifitindia.com/wp-content/uploads/2024/04/puzzle.gif" alt="Carousel 2" /> */}
                                    <img src={theopuzzle} alt="" />
                                </div>

                            </div>

                    }

                    {
                        isSmallScreen ?

                            <div style={{ userSelect: 'none' }} className='flex md:w-[80%]  xs:h-[320px] sm:h-[410px]   xsm:w-[70%]  xs:w-4/5  flex-col md:flex-row   bg-[#413651]  m-auto justify-center cursor-grab   items-center rounded-3xl xs:p-4 p-10 '>
                                <div className=" text-black    xs:w-full  md:w-4/5 ">
                                    <h1 className=" sm:text-xl  xs:text-lg    xsm:text-lg   text-[#D94496]  font-bold   sm:p-2  m-auto sm:mt-4   md:text-start text-center font-poppins"  >Forge Unbreakable Bonds.</h1>
                                    <div className='flex justify-end  lg:w-[310px] lg:h-[310px]   md:w-[270px] md:h-[270px] m-auto   sm:w-[230px] sm:h-[230px]  xs:w-[180px] xs:h-[180px]  xsm:w-[200px] '>
                                        {/* <img className='   ' src="https://trifitindia.com/wp-content/uploads/2024/04/charts.gif" alt="Carousel 3" /> */}
                                        <img  style={{width:"90%"}} src={forge} alt="" />

                                    </div>
                                    <p  className="lg:text-lg sm:text-base   xs:text-xs mt-4   xsm:text-xs  text-center tracking-wide text-[#f5f5f5]      m-auto  lg:mt-0 font-poppins  "  > {truncatedText_third}.</p>
                                </div>



                            </div>
                            :
                            // <div style={{ userSelect: 'none' }} className='flex md:w-[80%]   xsm:w-3/5  xs:w-4/5  flex-col md:flex-row   bg-[#413651]  m-auto justify-center cursor-grab   items-center rounded-3xl xs:p-4 p-10 '>
                            //     <div className=" text-black   xs:w-full  md:w-3/5 ">
                            //     <h1 className=" sm:text-xl  xs:text-lg    xsm:text-lg  tracking-wide text-[#D94496]  font-bold   relative   lg:right-[12px]  sm:p-2  m-auto sm:mt-4  text-start font-poppins "  >Forge Unbreakable Bonds</h1>
                            //         <p className="  xs:text-xs    xsm:text-base  text-start  text-[#f5f5f5]    m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins   "  > {fullText_first} </p>
                            //     </div>

                            //     <div style={{display:"flex", alignItems:"center", margin:"auto", justifyContent:"center",}} className='  lg:w-[310px] lg:h-[310px]  md:w-[270px] md:h-[270px]   sm:w-[230px] sm:h-[230px]  xs:w-[180px] xs:h-[180px]  xsm:w-[200px] xsm:h-[200px] '>
                            //         <img className='   ' src="https://trifitindia.com/wp-content/uploads/2024/04/puzzle.gif" alt="Carousel 2" />
                            //         {/* <img  className='lg:w-[80%] md:w-[70%]  ' style={{ border:"2px solid red"}} src={forge} alt="" /> */}
                            //         {/* <img src={forge} alt="" /> */}
                            //     </div>

                            // </div>
                            <div style={{ userSelect: 'none' }} className='flex md:w-[80%]   xsm:w-3/5  xs:w-4/5  flex-col md:flex-row  bg-[#413651]   m-auto justify-center cursor-grab   items-center rounded-3xl xs:p-4 p-10 '>
                            <div className=" text-black  xs:w-full  md:w-3/5 ">
                            <h1 className=" sm:text-xl  xs:text-lg    xsm:text-lg   text-[#D94496]  relative   lg:right-[12px]   font-bold   sm:p-2  m-auto sm:mt-4  text-start font-poppins "  > Forge Unbreakable Bonds.</h1>
                                <p className="  xs:text-xs    xsm:text-base  text-start   text-[#f5f5f5]     m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins  "  >   {fullText_first} </p>
                            </div>

                            <div className=' flex justify-center items-center lg:w-[310px] lg:h-[310px]  md:w-[270px] md:h-[270px]  sm:w-[230px] sm:h-[230px]  xs:w-[180px] xs:h-[180px]  xsm:w-[200px] xsm:h-[200px] '>
                                {/* <img className='   ' src="https://trifitindia.com/wp-content/uploads/2024/04/puzzle.gif" alt="Carousel 2" /> */}
                                <img  style={{width:"80%"}} src={forge} alt="" />
                            </div>

                        </div>


                    }

                </Carousel>
            </div>
        </div>
    );
}

export default Slider;















{/* <div className='bg-[rgba(93,27,188,1)] pb-16 pt-8 w-full'>
<div className='text-white   pb-4 sm:pb-0 '>
    <h1 className="lg:text-3xl font-bold sm:text-xl  xs:text-lg    xsm:text-lg  tracking-wide   w-4/5  xs:w-[100%] sm:w-4/5  sm:p-6  m-auto sm:mt-4  text-center font-poppins animate__animated animate__fadeIn  ">Become the Protagonist of Your Own Story</h1>
    <p className="lg:text-lg sm:text-lg  xs:text-xs    xsm:text-base  text-center tracking-wide   w-4/5  xsm:w-[80%]  lg:w-[70%]    m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins animate__animated animate__fadeIn  "  >In a distant land, hidden from the eyes of humans, four powerful clans ruled the wilderness. But now, you have the chance to become  the protagonist of your own transformative journey. Welcome to Trifit, where you’ll assume
        the role of a hero embarking on a quest for holistic self-improvement.</p>
</div>
<div >


    <Carousel showIndicators={false} swipeable={false} emulateTouch={true} showArrows={false} showStatus={false} showThumbs={false} autoPlay={true} interval={1500} infiniteLoop={true}   >
        <div style={{ userSelect: 'none' }} className='flex w-4/5     flex-col md:flex-row  bg-white  m-auto justify-center cursor-grab   items-center rounded-3xl p-10 '>
            <div className=" text-black">
                <h1 className="lg:text-2xl sm:text-xl  xs:text-lg    xsm:text-lg  tracking-wide   sm:p-6  m-auto sm:mt-4  text-start font-poppins animate__animated animate__fadeIn"  >Experience the Power of an AI Personal Trainer</h1>
                <p className="lg:text-lg sm:text-lg  xs:text-xs    xsm:text-base  text-start tracking-wide      m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins animate__animated animate__fadeIn  "  >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English..</p>
            </div>

            <div className=' xsm:w-4/5  md:w-full  '>
                <img style={{width:"300px", height:"250px"}}   className='   border-2 border-red-800 ' src="https://trifitindia.com/wp-content/uploads/2024/04/boxing.gif" alt="Carousel 1" />
            </div>

        </div>
        <div style={{ userSelect: 'none' }} className='flex w-4/5 m-auto    flex-col md:flex-row  cursor-grab  bg-white justify-center items-center rounded-3xl p-10 '>
            <div className="font-poppins text-black">
                <h1 className="lg:text-2xl sm:text-xl  xs:text-lg    xsm:text-lg  tracking-wide    sm:p-6  m-auto sm:mt-4  text-start font-poppins animate__animated animate__fadeIn"                  >Experience the Power of an AI Personal Trainer</h1>
                <p className="lg:text-lg sm:text-lg  xs:text-xs    xsm:text-base  text-start tracking-wide      m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins animate__animated animate__fadeIn  "  >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English..</p>
            </div>
            <div className=' xsm:w-4/5  md:w-full  '>
                <img style={{width:"300px", height:"250px"}}  className='   border-2 border-red-800 ' src="https://trifitindia.com/wp-content/uploads/2024/04/puzzle.gif" alt="Carousel 2" />
            </div>
        </div>
        <div style={{ userSelect: 'none' }} className='flex w-4/5 bg-white  flex-col md:flex-row  cursor-grab  m-auto justify-center items-center rounded-3xl p-10 '>
            <div className="font-poppins   text-black">
                <h1 className="lg:text-3xl sm:text-xl  xs:text-lg    xsm:text-lg  tracking-wide  sm:p-6  m-auto sm:mt-4  text-start font-poppins animate__animated animate__fadeIn"                  >Experience the Power of an AI Personal Trainer</h1>
                <p className="lg:text-lg sm:text-lg  xs:text-xs    xsm:text-base  text-start tracking-wide    m-auto xsm:mt-4 lg:mt-0 xsm:mb-8 font-poppins animate__animated animate__fadeIn  "  >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English..</p>
            </div>
            <div className=' xsm:w-4/5  md:w-full   '>
                <img style={{width:"300px", height:"250px"}}   className='   border-2 border-red-800 ' src="https://trifitindia.com/wp-content/uploads/2024/04/charts.gif" alt="Carousel 3" />
            </div>
        </div>
    </Carousel>
</div>
</div> */}