import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { IoArrowForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import second from "../../Images/second.jpeg";
import { SELECT_USERNAME } from '../../Redux/Onboardingreducer/actionTypes';

const Welcomepagesecond = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selectedusername = useSelector((store) => store.Onboardingreducer.username)


   const notify =  ()=>{
    toast.error('Please Pick a  Username!',{
        style:{
            border:"2px solid  #120426 ",
            fontWeight:"700",
            color :"#120426 "
          
        }

    })

   }  

    const handle_Set_Username = (e) => {

        const namevalue = e.target.value;
        dispatch({ type: SELECT_USERNAME, payload: namevalue })
        localStorage.setItem("username", (selectedusername));


    }


    const handleSetUsername = () => {
        if (selectedusername.trim().length === 0) {
            return notify()
        }

        navigate("/welcomethird")
    }

    return (
        <div className=" bg-[#120426]  flex items-center justify-center pb-12 ">
            <div className=" p-8 rounded-lg   w-[90%] xs:min-h-screen   xs:w-[100%]  sm:w-[95%]  m-auto">
                <div className="flex  xs:flex-col-reverse    md:flex-row justify-beween items-center">


                    <div className="  text-center     xs:w-[95%] xsm:w-4/5 sm:w-3/5  lg:w-1/2 text-white mb-8 md:mb-0">
                        <div className="  py-4 m-auto  lg:w-3/5 sm:w-4/5 xs:w-[95%]   md: w-[90%] font-poppins">
                            <input onChange={handle_Set_Username} className='rounded-2xl xs:pl-4 xs:pr-8  xs:text-sm  sm:text-xl sm:pl-8 sm: pr-14 text-start lg:py-4  py-2  text-black font-semibold  border-4 border-[#5D1BBC] w-[100%]' placeholder='Pick a username' type="text" />
                        </div >
                        <div className=" space-y-4">

                            <div>

                                <button onClick={handleSetUsername} className="bg-[#5D1BBC] flex justify-center group relative  font-poppins m-auto items-center text-white font-semibold py-3 lg:text-2xl md:text-xl sm:text-lg px-14 rounded-full border-4 border-white   hover:border-[#5D1BBC]   overflow-hidden">

                                    <div className="absolute left-0 h-full   w-0 py-3 rounded-full bg-white transition-all duration-1000 ease-in-out group-hover:w-full z-0"></div>

                                    <span className="relative z-10 transition-colors duration-1000 group-hover:text-[#5D1BBC]">
                                        Continue
                                    </span>
                                    <IoArrowForward className='inline lg:text-4xl relative z-10 ml-2 md:text-2xl sm:text-xl xs:text-lg transition-colors duration-1000 group-hover:text-[#5D1BBC]' />
                                </button>

                            </div>
                            <div className=' w-fit m-auto mt-4'>
                                <Link to="/welcomefirst">
                                    <button className="bg-[#5D1BBC] flex justify-center group relative font-poppins items-center text-white font-semibold py-3 lg:text-2xl md:text-xl sm:text-lg px-14 rounded-full border-4 border-white hover:border-[#5D1BBC]  overflow-hidden">

                                        <div className="absolute h-full right-0  w-0 py-3 rounded-full bg-white transition-all duration-1000 ease-in-out group-hover:w-full  z-0"></div>


                                        <IoMdArrowBack className='inline lg:text-4xl relative z-10 mr-2 md:text-2xl sm:text-xl xs:text-lg transition-colors duration-1000 group-hover:text-[#5D1BBC]' />


                                        <span className="relative z-10 transition-colors duration-1000 group-hover:text-[#5D1BBC]">
                                            Back
                                        </span>
                                    </button>
                                </Link>
                            </div>

                        </div>
                    </div>

                    <div className="lg:w-[500px]  lg:h-[500px] md:w-[400px] h-[400px] sm:w-[300px] sm:h-[300px] xs:w-[250px] xs:h-[250px] w-full    mb-16  m-auto">
                        <img
                            src={second}
                            alt="second"
                            className="w-[100%] xs:rounded-full h-[100%] m-auto  sm:rounded-none"
                        />
                    </div>


                </div>
            </div>
         
        </div>
    );
};





export default Welcomepagesecond