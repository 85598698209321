import { SignIn, SignUp } from "@clerk/clerk-react"
import { Route, Routes } from 'react-router-dom'

import PrivacyPolicyAndCondition from "../Components/PrivacyPolicyAndCondition"
import About from "../Pages/About/About"
import ContactUs from "../Pages/ContactUs/ContactUs"
import { Homepage } from "../Pages/Home/Homepage"
import Houseallocation from '../Pages/Onboarding/Houseallocation'
import Signuponboarding from '../Pages/Onboarding/Signuponboarding'
import Welcomepagefirst from '../Pages/Onboarding/Welcomepagefirst'
import Welcomepagesecond from '../Pages/Onboarding/Welcomepagesecond'
import Welcomepagethird from '../Pages/Onboarding/Welcomepagethird'





const CustomSignIn = () => {
    return (
        <div className="flex justify-center items-center bg-[#120426] shadow-lg h-screen">
            <div className="w-full sm:w-[90%] md:w-2/3 lg:w-1/2 xl:w-2/5 p-6 m-auto">
                <SignIn routing="path" path="/sign-in" fallbackRedirectUrl="/"  />
            </div>
        </div>
    );
};


const CustomSignUp = () => {
    return (
        <div className="flex justify-center items-center bg-[#120426] shadow-lg h-screen">
            <div className="w-full sm:w-[90%] md:w-2/3 lg:w-1/2 xl:w-2/5 p-6 m-auto">
            
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <SignUp routing="path" path="/sign-up" />
                </div>
            </div>
        </div>
    );
};


const Allroutes = () => {


    return (

        <Routes>
            <Route path="/sign-in/*"  element={<CustomSignIn />} />
            <Route path="/sign-up/*" element={<CustomSignUp />} />
            <Route path='/' element={<Homepage />} />
            <Route path='/about' element={<About />} />
            <Route path='/privacy' element={<PrivacyPolicyAndCondition />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/welcomefirst' element={<Welcomepagefirst />} />
            <Route path='/Welcomesecond' element={<Welcomepagesecond />} />
            <Route path='/welcomethird' element={<Welcomepagethird />} />
            <Route path='/houselocation' element={<Houseallocation />} />
            <Route path='/signuponboarrd' element={<Signuponboarding />} />

        </Routes>

    )
}

export default Allroutes