import "animate.css";
import React from 'react';
import toast from "react-hot-toast";
import { IoMdArrowBack } from 'react-icons/io';
import { IoArrowForward } from 'react-icons/io5';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import bee from "../../Images/bee.jpeg";
import bonobo from "../../Images/bonobo.jpeg";
import fourth from "../../Images/fourth.jpeg";
import orca from "../../Images/orca.jpeg";
import queencobra from "../../Images/queencobra.jpeg";
import { SELECT_HOUSE } from "../../Redux/Onboardingreducer/actionTypes";

const Houseallocation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const houseSelected = useSelector((store) => store.Onboardingreducer.house)

    const notify1 = () => {
        toast.error('Please select a House!', {
            style: {
                border: "2px solid  #120426 ",
                fontWeight: "700",
                color: "#120426 "

            }

        })

    }

    const notify2 = (toyname) => {
        toast.success(`You have selected ${toyname}`, {
            style: {
                border: "2px solid  #120426 ",
                fontWeight: "700",
                color: "#120426 "

            }


        })

    }


    const handleSetHouseBee = (e) => {
        let value = e.currentTarget.getAttribute("data-value");
        dispatch({ type: SELECT_HOUSE, payload: value })
        localStorage.setItem("house", (value));
        notify2("blocks")

        console.log(value, "value bee");
    }


    const handleSetHouseOrca = (e) => {
        let value = e.currentTarget.getAttribute("data-value");
        dispatch({ type: SELECT_HOUSE, payload: value })
        localStorage.setItem("house", (value));
        notify2("beach ball")
        console.log(value, "value orca");
    }



    const handleSetHouseCobra = (e) => {
        let value = e.currentTarget.getAttribute("data-value");
        dispatch({ type: SELECT_HOUSE, payload: value })
        localStorage.setItem("house", (value));
        notify2("jack in the box")
        console.log(value, "valu cobra");
    }


    const handlehousebonobo = (e) => {
        let value = e.currentTarget.getAttribute("data-value");
        dispatch({ type: SELECT_HOUSE, payload: value })
        localStorage.setItem("house", (value));
        notify2("drum")
        console.log(value, "value bonobo");
    }


    const handleSetHouse = () => {
        if (houseSelected !== null) {
            navigate("/signuponboarrd")
        }
        else {
            return notify1()
        }

    }



    return (
        <div className=" bg-[#120426]  pb-12 flex items-center justify-center ">
            <div className=" p-8 rounded-lg xs:pb-16  w-[90%] xs:min-h-screen     xs:w-[100%]  sm:w-[95%]  m-auto">
                <div className="flex  xs:flex-col-reverse  lg:flex-row justify-beween items-center">


                    <div className="  text-center  text-white mb-8 md:mb-0">
                        <h1 className="lg:text-5xl sm:text-4xl  xs:text-xl   xsm:text-3xl  tracking-wide   w-4/5  xs:w-[100%] sm:w-4/5  sm:p-6  m-auto sm:mt-4  font-semibold mb-6 font-poppins animate__animated animate__fadeIn  ">
                            Pick one toy and Find your House
                        </h1 >


                        <div className="grid lg:grid-cols-4 xs:grid-cols-2  xs:gap-6  xs:w-[98%]  xsm:gap-6   xsm:w-3/5  sm:gap-8 lg:gap-4 place-items-center md:w-1/2 lg:w-4/5 m-auto p-2 mb-4">
                            <div onClick={handleSetHouseBee} data-value={"6651d53a01a1672d0ab45b5b"} className="border-4 border-white rounded-2xl  hover:bg-[#120426]  transition-all duration-600     bg-[#5D1BBC] p-4 cursor-pointer flex items-center justify-center">
                                <img
                                    className="w-16 lg:h-24 lg:w-24   sm:w-20 md:w-24  xl:w-32 h-auto"
                                    src={bee}
                                    alt="bee"
                                />
                            </div>
                            <div onClick={handlehousebonobo} data-value={"6651d53a01a1672d0ab45b5e"} className="border-4 border-white rounded-2xl      hover:bg-[#120426]  transition-all duration-600     bg-[#5D1BBC] p-4 cursor-pointer flex items-center justify-center">
                                <img
                                    className="w-16 lg:h-24 lg:w-24   sm:w-20 md:w-24 xl:w-32 h-auto"
                                    src={bonobo}
                                    alt="bonobo"
                                />
                            </div>
                            <div onClick={handleSetHouseOrca} data-value={"6651d53a01a1672d0ab45b5c"} className="border-4 border-white rounded-2xl    hover:bg-[#120426]  transition-all duration-600    bg-[#5D1BBC] p-4 cursor-pointer flex items-center justify-center">
                                <img
                                    className="w-16 lg:h-24 lg:w-24   sm:w-20 md:w-24  xl:w-32 h-auto"
                                    src={orca}
                                    alt="orca"
                                />
                            </div>
                            <div onClick={handleSetHouseCobra} data-value={"6651d53a01a1672d0ab45b5d"} className="border-4 border-white rounded-2xl   hover:bg-[#120426]  transition-all duration-600  bg-[#5D1BBC] p-4 cursor-pointer flex items-center justify-center">
                                <img
                                    className="w-16 lg:h-24 lg:w-24 sm:w-20 md:w-24  xl:w-32 h-auto"
                                    src={queencobra}
                                    alt="queencobra"
                                />
                            </div>
                        </div>



                        <div className=" space-y-4">
                            <div onClick={handleSetHouse}>

                                <button className="bg-[#5D1BBC] flex justify-center group relative  font-poppins m-auto items-center text-white font-semibold py-3 lg:text-2xl md:text-xl sm:text-lg px-14 rounded-full border-4 border-white   hover:border-[#5D1BBC]   overflow-hidden">

                                    <div className="absolute left-0 h-full   w-0 py-3 rounded-full bg-white transition-all duration-1000 ease-in-out group-hover:w-full z-0"></div>

                                    <span className="relative z-10 transition-colors duration-1000 group-hover:text-[#5D1BBC]">
                                        Continue
                                    </span>
                                    <IoArrowForward className='inline lg:text-4xl relative z-10 ml-2 md:text-2xl sm:text-xl xs:text-lg transition-colors duration-1000 group-hover:text-[#5D1BBC]' />
                                </button>
                            </div>

                            <div className=' w-fit m-auto mt-4'>
                                <Link to="/welcomethird">
                                    <button className="bg-[#5D1BBC] flex justify-center group relative font-poppins items-center text-white font-semibold py-3 lg:text-2xl md:text-xl sm:text-lg px-14 rounded-full border-4 border-white hover:border-[#5D1BBC]  overflow-hidden">

                                        <div className="absolute h-full right-0  w-0 py-3 rounded-full bg-white transition-all duration-1000 ease-in-out group-hover:w-full  z-0"></div>


                                        <IoMdArrowBack className='inline lg:text-4xl relative z-10 mr-2 md:text-2xl sm:text-xl xs:text-lg transition-colors duration-1000 group-hover:text-[#5D1BBC]' />

                                        <span className="relative z-10 transition-colors duration-1000 group-hover:text-[#5D1BBC]">
                                            Back
                                        </span>
                                    </button>
                                </Link>
                            </div>

                        </div>
                    </div>


                    <div className="lg:w-[500px]  lg:h-[500px] md:w-[400px] h-[400px] sm:w-[300px] sm:h-[300px] xs:w-[250px] xs:h-[250px] w-full    mb-8  lg:mt-12  m-auto">
                        <img
                            src={fourth}
                            alt="fourth"
                            className="w-[100%] xs:rounded-full h-[100%] m-auto   sm:rounded-none"
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};





export default Houseallocation